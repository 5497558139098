import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { useInput } from "../Hooks";
import { mainAction } from "../Redux/Actions";
import { Alertwarning, Alertsuccess } from "../Utils";
import { APIKey } from "../Services";
import I18n from "../Language";
import { SelectCity } from ".";
const ContactFormLandingComp = React.forwardRef(() => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();

  const [Name, bindName, setName] = useInput("");
  const [Email, bindEmail, setEmail] = useInput("");
  const [Phone, bindPhone, setPhone] = useInput("");
  const [Content, bindContent, setContent] = useInput("");
  const [LadingCode] = useInput("");
  const [Type] = useState(0);
  const [TypeContact] = useState("");

  const [TypeComplain] = useState(1);

  const [Url, setUrl] = useState("https://gtelpost.vn");
  const history = useHistory();
  useEffect(() => {
    let domain = "https://gtelpost.vn";
    setUrl(domain + location?.pathname);
  }, [location]);
  /* const [AreaId, setAreaId] = useState(0);
  const [Area, setArea] = useState(""); */

  const [CityId, setCityId] = useState(0);
  const [CityName, setCityName] = useState("");

  const NameRef = useRef();
  const EmailRef = useRef();
  const PhoneRef = useRef();
  const ContentRef = useRef();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex =
      /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;
    return phoneRegex.test(phone);
  };

  const onAction = async () => {
    if (!validateEmail(Email)) {
      Alertwarning(I18n.t("ContactForm.EmailInvalidate"));
      EmailRef.current.focus();
      return;
    }
    if (!validatePhone(Phone)) {
      Alertwarning(I18n.t("ContactForm.PhoneInvalidate"));
      PhoneRef.current.focus();
      return;
    }
    if (Name === "") {
      Alertwarning(I18n.t("ContactForm.RequiredName"));
      NameRef.current.focus();
      return;
    } else if (Email === "") {
      Alertwarning(I18n.t("ContactForm.RequiredEmail"));
      EmailRef.current.focus();
      return;
    } else if (Phone === "") {
      Alertwarning(I18n.t("ContactForm.RequiredPhone"));
      PhoneRef.current.focus();
      return;
    } else if (Content === "") {
      Alertwarning(I18n.t("ContactForm.RequiredContent"));
      ContentRef.current.focus();
      return;
    } else if (Type === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredType"));
      return;
    } else if (CityId === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredArea"));
      return;
    } else {
      setDisabled(true);
      if (TypeContact === "CSKH") {
        //Nếu khách chọn khiếu nại dịch vụ
        let params = {
          CustomerId: 0,
          Name: Name,
          Phone: Phone,
          LadingCode: LadingCode,
          Type: parseInt(TypeComplain),
          Content: Content,
          SendFrom: "TỪ GTELPOST WEBSITE",
          CityId: CityId,
          CityName: CityName,
          TypeReceipt: 1,
        };
        const pr = {
          ApiKey: APIKey,
          Json: JSON.stringify(params),
          func: "APIC_spCustomerComplain_JsonAuto",
          IsLoading: true,
        };
        try {
          const list = await mainAction.API_spCallServerSystem(pr, dispatch);
          if (list.length > 0) {
            history.push("/thankyou");
            Alertsuccess(I18n.t("ContactForm.Success"));
            onReset();
            onSendNofity("ComplainCreate");
          }
          setDisabled(false);
        } catch (err) {
          setDisabled(false);
        }
      } else {
        // Nếu khách chọn tư vấn/báo giá
        const pr = {
          Json:
            '{"Url":"' +
            Url +
            '","Name":"' +
            Name +
            '","Email":"' +
            Email +
            '","Phone":"' +
            Phone +
            '","ContactContent":"' +
            Content +
            '", "Type":"' +
            Type +
            '", "TypeName":"' +
            TypeContact +
            '", "AreaId":"' +
            CityId +
            '", "AreaName": "' +
            CityName +
            '","Status":1, "StatusName":"Chưa xử lý"}',
          func: "CRM_spCustomerContactRequest_Save",
        };
        try {
          const list = await mainAction.API_spCallServerSystem(pr, dispatch);
          if (list.length > 0) {
            history.push("/thankyou");
            Alertsuccess(I18n.t("ContactForm.Success"));
            onReset();
            onSendNofity("ShippingConsulting");
          }
          setDisabled(false);
        } catch (err) {
          setDisabled(false);
        }
      }
    }
  };

  const onReset = () => {
    setName("");
    setPhone("");
    setEmail("");
    setContent("");
  };

  const onSendNofity = async (functionsend) => {
    const NotifiParam = {
      Json: JSON.stringify({
        CustomerId: 0,
        FuncSend: functionsend,
        SendFrom: "WEBSITE gtelpost.vn",
        CustomerProvince: CityId,
        JsonData: [
          {
            Name: Name,
            Phone: Phone,
            Content: Content,
          },
        ],
      }),
      func: "APIC_spSendNotification",
      API_key: APIKey,
    };
    const resultNotify = await mainAction.API_spCallServerSystem(
      NotifiParam,
      dispatch
    );
    if (resultNotify.length > 0) return;
  };

  const onSelectCity = (item) => {
    setCityId(item.value);
    setCityName(item.label);
  };

  return (
    <div className="form form_group_landing" aria-autocomplete="off">
      <div className="form-group">
        <label className="special-label">
          Họ và Tên <span className="text-red">(*)</span>
        </label>
        <input
          className="form-control"
          type="text"
          ref={NameRef}
          value={Name}
          {...bindName}
          maxLength="50"
          placeholder={I18n.t("ContactForm.InputName")}
          required
        />
      </div>
      <div className="form-group">
        <label className="special-label">
          Email <span className="text-red">(*)</span>
        </label>
        <input
          className="form-control"
          type="text"
          ref={EmailRef}
          value={Email}
          onChange={(e) => handleEmailChange(e)}
          {...bindEmail}
          maxLength="50"
          placeholder={I18n.t("ContactForm.InputEmail")}
          required
        />
      </div>
      <div className="form-group">
        <label className="special-label">
          Số điện thoại <span className="text-red">(*)</span>
        </label>
        <input
          className="form-control"
          type="text"
          ref={PhoneRef}
          value={Phone}
          onChange={(e) => handlePhoneChange(e)}
          {...bindPhone}
          maxLength="15"
          placeholder={I18n.t("ContactForm.InputPhone")}
          required
        />
      </div>
      <div className="form-group">
        <label className="special-label">
          Chọn khu vực của bạn <span className="text-red">(*)</span>
        </label>
        <div className="form-control_mobile" style={{ color: "#19191d" }}>
          <SelectCity
            key={"CityTo"}
            className={"form-control"}
            Disabled={disabled}
            onActive={CityId}
            onSelected={(item) => onSelectCity(item)}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="special-label">
          Nội dung <span className="text-red">(*)</span>
        </label>
        <textarea
          className="form-control form-control_mobile"
          rows="3"
          ref={ContentRef}
          value={Content}
          {...bindContent}
          placeholder="Chúng tôi có thể giúp gì cho bạn ?"
          required
        ></textarea>
      </div>
      <div
        className="form-group text-right mb20"
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          top: "15px",
        }}
      >
        <button
          type="text"
          className="btn btn-success pd-30"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
          disabled={disabled}
          onClick={(e) => onAction()}
        >
          NHẬN BÁO GIÁ NGAY
        </button>
      </div>
    </div>
  );
});

export const ContactFormLanding = React.memo(ContactFormLandingComp);
