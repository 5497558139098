import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { useInput } from "../Hooks";
import { mainAction } from "../Redux/Actions";
import { Alertwarning, Alertsuccess, FormatMoney, FormatDateJson } from "../Utils";
import { APIKey, WEB_SETTING } from "../Services";
import I18n from '../Language';
import {SelectCity, SelectDistrict} from "./";

const TrackingPriceComp = ({

}, ref) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const [ShowPrice, setShowPrice] = useState(0);
    const [Weight, bindweight, setWeight] = useInput(0);
    const [COD, , bindCOD, setCOD] = useInput(0);
    const [Insured, bindInsured, setInsured] = useInput(0);
    const [Length, bindLength, setLength] = useInput(0);
    const [Width, bindWidth, setWidth] = useInput(0);
    const [Height, bindHeight, setHeight] = useInput(0);
    const [CityFromId, setCityFromId] = useState(0);
    const [DistrictFromId, setDistrictFromId] = useState(0);
    const [CityToId, setCityToId] = useState(0);
    const [DistrictToId, setDistrictToId] = useState(0);
    const [ListPrice, setListPrice] = useState(0);

    const CODRef = useRef();
    const InsuredRef = useRef();
    const WeightRef = useRef();
    const LengthRef = useRef();
    const WidthRef = useRef();
    const HeightRef = useRef();

    const onSelectCityFrom = (item) => {
        setCityFromId(item.value);
    }
    const onSelectDistictFrom = (item) => {
        setDistrictFromId(item.value);
    }
    const onSelectCityTo = (item) => {
        setCityToId(item.value);
    }
    const onSelectDistrictTo = (item) => {
        setDistrictToId(item.value);
    }

    const onTrackingPrice = async () => {
        if (CityFromId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredCityFromId"));
            return;
        } else if (CityToId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredCityToId"));
            return;
        }
        else if (DistrictToId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredDistrictToId"));
            return;
        }
        else if (Weight === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredWeight"));
            WeightRef.current.focus();
            return;
        }

        let pr = {
            CustomerId: 0,
            CityGoId: CityFromId,
            CityToId: CityToId,
            DistrictTo: DistrictToId,
            WardToId: 0,
            Weight: Weight,
            Mass: 0,
            Number: 0,
            HHKG: Insured,
            COD: COD
        };
        const params = {
            ApiKey: APIKey,
            Json: JSON.stringify(pr),
            func: "CPN_spLading_EstimatesPrice",
            IsLoading: true
        };

        try {
            setDisabled(true);
            const list = await mainAction.API_spCallServerSystem(params, dispatch);
            setListPrice(list.filter(p=>p.ServiceName!=="My NETCO" && p.ServiceName!=="Phát hỏa tốc"));
            setShowPrice(1);
            setDisabled(false);
        } catch (err) {
            setDisabled(false);
        }
    }

    return (
        <div className="row">
            {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="frm-label">{I18n.t('TrackingPrice.ShippingRange')}</label>
                                        <select className="form-control">
                                            <option value="0">{I18n.t('TrackingPrice.Domestic')}</option>
                                            <option value="1">{I18n.t('TrackingPrice.International')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="frm-label">{I18n.t('TrackingPrice.NumberPacked')}</label>
                                        <input type="text" className="form-control" placeholder={I18n.t('TrackingPrice.InputNumberPacked')} />
                                    </div>
                                </div> */}
            <div className="col-md-6">
                <div className="form-group">
                    <label className="frm-label">{I18n.t('TrackingPrice.CityFrom')} <span className="required">*</span></label>
                    <SelectCity key={"CityFrom"}
                        className={"form-control"}
                        Disabled={disabled}
                        onActive={CityFromId}
                        onSelected={(item) => onSelectCityFrom(item)}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="frm-label">{I18n.t('TrackingPrice.DistrictFrom')}</label>
                    <SelectDistrict
                        className={"form-control"}
                        onActive={DistrictFromId}
                        ParentID={CityFromId}
                        onSelected={(item) => {
                            onSelectDistictFrom(item);
                        }}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="frm-label">{I18n.t('TrackingPrice.CityTo')} <span className="required">*</span></label>
                    <SelectCity key={"CityTo"}
                        className={"form-control"}
                        Disabled={disabled}
                        onActive={CityToId}
                        onSelected={(item) => onSelectCityTo(item)}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="frm-label">{I18n.t('TrackingPrice.DistrictTo')} <span className="required">*</span></label>
                    <SelectDistrict
                        className={"form-control"}
                        onActive={DistrictToId}
                        ParentID={CityToId}
                        onSelected={(item) => {
                            onSelectDistrictTo(item);
                        }}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="frm-label">{I18n.t('TrackingPrice.Weight')} (Gram) <span className="required">*</span></label>
                    <input type="number" className="form-control" ref={WeightRef} required value={Weight} {...bindweight} placeholder={I18n.t('TrackingPrice.InputWeight')} />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="frm-label">{I18n.t('TrackingPrice.COD')} (VNĐ)</label>
                    <input type="number" max="9999999999" className="form-control" ref={CODRef} value={COD} {...bindCOD} placeholder={I18n.t('TrackingPrice.InputCOD')} />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="frm-label">{I18n.t('TrackingPrice.Insured')} (VNĐ)</label>
                    <input type="number" max="9999999999" className="form-control" ref={InsuredRef} value={Insured} {...bindInsured} placeholder={I18n.t('TrackingPrice.InputInsured')} />
                </div>
            </div>
            <div className="col-md-6">
                <label className="frm-label">{I18n.t('TrackingPrice.Size')} ({I18n.t('TrackingPrice.Length')} x {I18n.t('TrackingPrice.Width')} x {I18n.t('TrackingPrice.Height')})  (cm)</label>
                <div className="row">
                    <div className="col-xs-4">
                        <div className="form-group">
                            <input type="number" className="form-control" ref={LengthRef} value={Length} {...bindLength} placeholder={I18n.t('TrackingPrice.Length')} />
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <div className="form-group">
                            <input type="number" className="form-control" ref={WidthRef} value={Width} {...bindWidth} placeholder={I18n.t('TrackingPrice.Width')} />
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <div className="form-group">
                            <input type="number" className="form-control" ref={HeightRef} value={Height} {...bindHeight} placeholder={I18n.t('TrackingPrice.Height')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 text-center">
                <button type="button" className="btn btn-success pd-30" disabled={disabled} onClick={(e) => onTrackingPrice()}>{I18n.t('TrackingPrice.TrackingPrice')}</button>
                <a target="_blank" href="/quy-dinh-chung?title=quy-dinh-vat-cam-gui-va-gui-co-dieu-kien" className="btn btn-outline">{I18n.t('TrackingPrice.RegulationsAndGoods')}</a>
            </div>
            <p className="text-center">Lưu ý: Cước phí ước tính chưa bao gồm phí phát ngoại tuyến, VAT và phụ phí xăng dầu</p>
            {
                ShowPrice === 1 ?
                    (
                        <div className="col-md-12">
                            <div className="h3 text-green">{I18n.t('TrackingPrice.TheRightServiceForYou')}</div>
                            <div className="row">
                                {
                                    ListPrice.map((item, index) => {
                                        let _imgThumb = "/assets/img/icon80/cpn.png";
                                        return (
                                            <div className="col-md-4" key={"Price" + index}>
                                                <div className="box-item">
                                                    <img alt={item.ServiceName} className="img-normal" src={_imgThumb} />
                                                    <div className="text-bolder">{item.ServiceName}</div>
                                                    <div className="text-lg-bolder">{FormatMoney(item.Amount)} VNĐ</div>
                                                    <div className="text-sm">{I18n.t('TrackingPrice.EstimatedDealine')}: {FormatDateJson(item.DealineTime, 1)}</div>
                                                    <a href={`${WEB_SETTING.web_customer}/tao-nhanh-van-don`} className="btn btn-success">{I18n.t('TrackingPrice.CreateLading')}</a>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : (<></>)
            }
        </div>
    );
};

export const TrackingPrice = React.memo(TrackingPriceComp);
