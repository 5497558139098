import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useInput } from "../Hooks";
import { mainAction } from "../Redux/Actions";
import {
  Alerterror,
  Alertsuccess,
  Alertwarning,
  FormatDateJson,
  FormatMoney,
} from "../Utils";
import { APIKey } from "../Services";
import { SelectWeight, LoadingPage, LoadingPageWeb } from "../Common";
import I18n from "../Language";
import ReactHtml from "raw-html-react";
import { Alert } from "reactstrap";
import ChatLayout from "../Layout/ChatLayout";
import { ChatBotClone } from "./ChatbotClone";
import $ from "jquery";

const ChatBotComp = ({}) => {
  const listState = [
    { value: 0, label: "Chọn tỉnh thành" },
    { value: 1, label: "ha noi" },
    { value: 1, label: "hni" },
    { value: 32, label: "da nang" },
    { value: 50, label: "ho chi minh" },
    { value: 50, label: "hcm" },
    { value: 50, label: "sgn" },
    { value: 47, label: "binh duong" },
    { value: 59, label: "can tho" },
    { value: 2, label: "ha giang" },
    { value: 3, label: "cao bang" },
    { value: 4, label: "bac can" },
    { value: 5, label: "tuyen quang" },
    { value: 6, label: "lao cai" },
    { value: 7, label: "đien bien" },
    { value: 8, label: "lai chau" },
    { value: 9, label: "son la" },
    { value: 10, label: "yen bai" },
    { value: 11, label: "hoa binh" },
    { value: 12, label: "thai nguyen" },
    { value: 13, label: "lang son" },
    { value: 14, label: "quang ninh" },
    { value: 15, label: "bac giang" },
    { value: 16, label: "phu tho" },
    { value: 17, label: "vinh phuc" },
    { value: 18, label: "bac ninh" },
    { value: 19, label: "hai duong" },
    { value: 20, label: "hai phong" },
    { value: 21, label: "hung yen" },
    { value: 22, label: "thai binh" },
    { value: 23, label: "ha nam" },
    { value: 24, label: "nam đinh" },
    { value: 25, label: "ninh binh" },
    { value: 26, label: "thanh hoa" },
    { value: 27, label: "nghe an" },
    { value: 28, label: "ha tinh" },
    { value: 29, label: "quang binh" },
    { value: 30, label: "quang tri" },
    { value: 31, label: "hue" },
    { value: 33, label: "quang nam" },
    { value: 34, label: "quang ngai" },
    { value: 35, label: "binh dinh" },
    { value: 36, label: "phu yen" },
    { value: 37, label: "khanh hoa" },
    { value: 38, label: "ninh thuan" },
    { value: 39, label: "binh thuan" },
    { value: 40, label: "kon tum" },
    { value: 41, label: "gia lai" },
    { value: 42, label: "đak lak" },
    { value: 43, label: "đak nong" },
    { value: 44, label: "lam đong" },
    { value: 45, label: "binh phuoc" },
    { value: 46, label: "tay ninh" },
    { value: 48, label: "đong nai" },
    { value: 49, label: "vung tau" },
    { value: 51, label: "long an" },
    { value: 52, label: "tien giang" },
    { value: 53, label: "ben tre" },
    { value: 54, label: "tra vinh" },
    { value: 55, label: "vinh long" },
    { value: 56, label: "đong thap" },
    { value: 57, label: "an giang" },
    { value: 58, label: "kien giang" },
    { value: 60, label: "hau giang" },
    { value: 61, label: "soc trang" },
    { value: 62, label: "bac lieu" },
    { value: 63, label: "ca mau" },
    { value: 0, label: "Chọn tỉnh thành" },
    { value: 1, label: "Hà Nội" },
    { value: 32, label: "Đà Nẵng" },
    { value: 50, label: "Hồ Chí Minh" },
    { value: 47, label: "Bình Dương" },
    { value: 59, label: "Cần Thơ" },
    { value: 2, label: "Hà Giang" },
    { value: 3, label: "Cao Bằng" },
    { value: 4, label: "Bắc Kạn" },
    { value: 5, label: "Tuyên Quang" },
    { value: 6, label: "Lào Cai" },
    { value: 7, label: "Điện Biên" },
    { value: 8, label: "Lai Châu" },
    { value: 9, label: "Sơn La" },
    { value: 10, label: "Yên Bái" },
    { value: 11, label: "Hòa Bình" },
    { value: 12, label: "Thái Nguyên" },
    { value: 13, label: "Lạng Sơn" },
    { value: 14, label: "Quảng Ninh" },
    { value: 15, label: "Bắc Giang" },
    { value: 16, label: "Phú Thọ" },
    { value: 17, label: "Vĩnh Phúc" },
    { value: 18, label: "Bắc Ninh" },
    { value: 19, label: "Hải Dương" },
    { value: 20, label: "Hải Phòng" },
    { value: 21, label: "Hưng Yên" },
    { value: 22, label: "Thái Bình" },
    { value: 23, label: "Hà Nam" },
    { value: 24, label: "Nam Định" },
    { value: 25, label: "Ninh Bình" },
    { value: 26, label: "Thanh Hóa" },
    { value: 27, label: "Nghệ An" },
    { value: 28, label: "Hà Tĩnh" },
    { value: 29, label: "Quảng Bình" },
    { value: 30, label: "Quảng Trị" },
    { value: 31, label: "Huế" },
    { value: 33, label: "Quảng Nam" },
    { value: 34, label: "Quảng Ngãi" },
    { value: 35, label: "Bình Định" },
    { value: 36, label: "Phú Yên" },
    { value: 37, label: "Khánh Hòa" },
    { value: 38, label: "Ninh Thuận" },
    { value: 39, label: "Bình Thuận" },
    { value: 40, label: "Kon Tum" },
    { value: 41, label: "Gia Lai" },
    { value: 42, label: "Đắk Lắk" },
    { value: 43, label: "Đắk Nông" },
    { value: 44, label: "Lâm Đồng" },
    { value: 45, label: "Bình Phước" },
    { value: 46, label: "Tây Ninh" },
    { value: 48, label: "Đồng Nai" },
    { value: 49, label: "Vũng Tàu" },
    { value: 51, label: "Long An" },
    { value: 52, label: "Tiền Giang" },
    { value: 53, label: "Bến Tre" },
    { value: 54, label: "Trà Vinh" },
    { value: 55, label: "Vĩnh Long" },
    { value: 56, label: "Đồng Tháp" },
    { value: 57, label: "An Giang" },
    { value: 58, label: "Kiên Giang" },
    { value: 60, label: "Hậu Giang" },
    { value: 61, label: "Sóc Trăng" },
    { value: 62, label: "Bạc Liêu" },
    { value: 63, label: "Cà Mau" },

    { value: 0, label: "chọn tỉnh thành" },
    { value: 1, label: "hà nội" },
    { value: 32, label: "đà nẵng" },
    { value: 50, label: "hồ chí minh" },
    { value: 47, label: "bình dương" },
    { value: 59, label: "cần thơ" },
    { value: 2, label: "hà giang" },
    { value: 3, label: "cao bằng" },
    { value: 4, label: "bắc kạn" },
    { value: 5, label: "tuyên quang" },
    { value: 6, label: "lào cai" },
    { value: 7, label: "điện biên" },
    { value: 8, label: "lai châu" },
    { value: 9, label: "sơn la" },
    { value: 10, label: "yên bái" },
    { value: 11, label: "hòa bình" },
    { value: 12, label: "thái nguyên" },
    { value: 13, label: "lạng sơn" },
    { value: 14, label: "quảng ninh" },
    { value: 15, label: "bắc giang" },
    { value: 16, label: "phú thọ" },
    { value: 17, label: "vĩnh phúc" },
    { value: 18, label: "bắc ninh" },
    { value: 19, label: "hải dương" },
    { value: 20, label: "hải phòng" },
    { value: 21, label: "hưng yên" },
    { value: 22, label: "thái bình" },
    { value: 23, label: "hà nam" },
    { value: 24, label: "nam định" },
    { value: 25, label: "ninh bình" },
    { value: 26, label: "thanh hóa" },
    { value: 27, label: "nghệ an" },
    { value: 28, label: "hà tĩnh" },
    { value: 29, label: "quảng bình" },
    { value: 30, label: "quảng trị" },
    { value: 31, label: "huế" },
    { value: 33, label: "quảng nam" },
    { value: 34, label: "quảng ngãi" },
    { value: 35, label: "bình định" },
    { value: 36, label: "phú yên" },
    { value: 37, label: "khánh hòa" },
    { value: 38, label: "ninh thuận" },
    { value: 39, label: "bình thuận" },
    { value: 40, label: "kon tum" },
    { value: 41, label: "gia lai" },
    { value: 42, label: "đắk lắk" },
    { value: 43, label: "đắk nông" },
    { value: 44, label: "lâm đồng" },
    { value: 45, label: "bình phước" },
    { value: 46, label: "tây ninh" },
    { value: 48, label: "đồng nai" },
    { value: 49, label: "vũng tàu" },
    { value: 51, label: "long an" },
    { value: 52, label: "tiền giang" },
    { value: 53, label: "bến tre" },
    { value: 54, label: "trà vinh" },
    { value: 55, label: "vĩnh long" },
    { value: 56, label: "đồng tháp" },
    { value: 57, label: "an giang" },
    { value: 58, label: "kiên giang" },
    { value: 60, label: "hậu giang" },
    { value: 61, label: "sóc trăng" },
    { value: 62, label: "bạc liêu" },
    { value: 63, label: "cà mau" },
  ];

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [dataSubject, setdataSubject] = useState([]);
  const [dataRandom, setdataRandom] = useState([]);
  const [dataAnswer, setdataAnswer] = useState([]);
  const [LocationData, setLocationData] = useState([]);
  const [dataChat, setdataChat] = useState([]);
  const [SubjectId, setSubjectId] = useState(0);
  const [txtSearch, setTextSearch] = useState("");
  const [Status, setStatus] = useState(0);
  const [StatusBtn, setStatusBtn] = useState("");

  const [ChatKH, setChatKH] = useState();
  const [chatbottimer, setchatbottimer] = useState(null);
  const [hellotimer, sethellotimer] = useState(null);
  const [endtimer, setendtimer] = useState(null);
  const [Chatvalue, setChatvalue] = useState("");

  const NameKh = localStorage.getItem("Name");
  const NameBot = localStorage.getItem("NameBot");
  const id = parseInt(localStorage.getItem("IdKh"));
  const idbot = parseInt(localStorage.getItem("IdBot"));
  const EmailKh = localStorage.getItem("Email");
  const PhoneKh = parseInt(localStorage.getItem("Phone"));
  const Phone = localStorage.getItem("Phone");
  let Gender = localStorage.getItem("Gender");
  if (Gender === null) {
    Gender = "Anh/Chị";
  }
  const Lat = localStorage.getItem("lat");
  const Long = localStorage.getItem("long");
  const [ChangeCS, setChangeCS] = useState("");
  const [OrderData, setOrderData] = useState("");
  const [Array, setArray] = useState([]);
  const [PriceCheck, setPriceCheck] = useState([]);
  const [PriceWeight, setPriceWeight] = useState(0);
  const [PriceDetail, setPriceDetail] = useState("");
  const [TimeDetail, setTimeDetail] = useState("");
  const [BillDetail, setBillDetail] = useState("");
  const [CustomerCodeStatus, setCustomerCodeStatus] = useState("");
  const [OrderAddreStatus, setOrderAddreStatus] = useState("");
  const [OrderWeightStatus, setOrderWeightStatus] = useState("");
  const [WeightUnit, setWeightUnit] = useState();
  const [WeightId2, setWeightId2] = useState(1);
  const [WeightName, setWeightName] = useState("kilogram");
  const [ConfirmStatus, setConfirmStatus] = useState("OK");
  const [CustomerStatus, setCustomerStatus] = useState("OK");
  const [District, setDistrict] = useState(0);
  const [ComplainStatus, setComplainStatus] = useState(1);
  const [Error, setError] = useState("");

  const [OrderMass, setOrderMass] = useState(0);
  const [OrderDVC, setOrderDVC] = useState("kilogram");
  const [CustomerCode, bindCustomerCode, setCustomerCode] = useInput("");
  const [OrderWeight, bindOrderWeight, setOrderWeight] = useInput("");
  const [OrderAddre, bindOrderAddre, setOrderAddre] = useInput("");
  const [OrderPhone, bindOrderPhone, setOrderPhone] = useInput("");
  const [OrderNote, bindOrderNote, setOrderNote] = useInput("");
  const CustomerCodeRef = useRef();
  const OrderAddressRef = useRef();
  const OrderWeighttRef = useRef();
  const OrderNoteRef = useRef();
  const [GetGoods, setGetGoods] = useState([
    { value: 9, label: "lên lấy " },
    { value: 11, label: "xuống lấy " },
    { value: 8, label: "qua lấy " },
    { value: 10, label: "muốn tạo order" },
    { value: 8, label: "lấy " },
    { value: 8, label: "lấy hàng" },
    { value: 8, label: "lấy thư" },
    { value: 8, label: "lấy bưu phẩm" },
    { value: 5, label: "tra lấy hàng" },
  ]);

  const [GetPrices, setGetPrices] = useState([
    { value: 1, label: "hết bao nhiêu" },
    { value: 2, label: "giá cước phí chuyển" },
    { value: 2, label: "cước phí chuyển" },
    { value: 2, label: "giá cước chuyển thư" },
    { value: 2, label: "cước phí chuyển thư" },
    { value: 3, label: "bao nhiêu tiền" },
    { value: 5, label: "phí chuyển từ" },
    { value: 5, label: "phí chuyển thư" },
    { value: 6, label: "tốn bao nhiêu" },
    { value: 2, label: "tham khảo giá" },
    { value: 2, label: "bao nhiêu tiền " },
    { value: 2, label: "cần báo giá" },
    { value: 2, label: "tư vấn giá" },
    { value: 4, label: "giá cước" },
    { value: 2, label: "cước phí" },
    { value: 5, label: "tra giá" },
  ]);

  const [GetTimes, setGetTimes] = useState([
    { value: 5, label: "thời gian giao" },
    { value: 5, label: "mất bao lâu " },
    { value: 5, label: "tra thời gian" },
  ]);

  const [GetBill, setGetBill] = useState([
    { value: 5, label: "check bill" },
    { value: 5, label: "kiểm vận đơn" },
    { value: 5, label: " hỏi vận đơn" },
    { value: 5, label: "tra bill" },
  ]);

  const [GetComplain, setGetComplain] = useState([
    { value: 5, label: "khiếu nại" },
    { value: 5, label: "lâu " },
    { value: 5, label: "trễ" },
  ]);

  const [LoadingStart, setLoadingStart] = useState(true);
  const [data, setData] = useState([
    {
      value: 0,
      label: "Diễm Quỳnh",
      src: "https://64.media.tumblr.com/868473cc5a3d8042325f03c9e4679920/tumblr_pxz2bzwhAh1w89qpgo1_1280.jpg",
    },
    {
      value: 1,
      label: "Ngọc Phương",
      src: "https://i.pinimg.com/564x/90/08/07/90080760e0e58ccc4e09657cbe65d1c7.jpg",
    },
    {
      value: 2,
      label: "Mỹ Trinh",
      src: "https://i.pinimg.com/564x/28/55/77/285577af2ea3f4e75a73cc18d5c36857.jpg",
    },
  ]);

  useEffect(() => {
    if (ChangeCS === "") {
      const name = data[Math.floor(Math.random() * data.length)];
      setChangeCS(name);
    }
    let b = document.getElementById("ad");
    b.focus();
  }, []);

  useEffect(() => {
    Task_spRandomChatbotMessage_List();
  }, [txtSearch]);
  const back = useRef(null);
  useEffect(() => {
    if (back !== "" && back !== undefined && back.current !== null) {
      back.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [dataChat, Status]);

  //lọc câu hỏi theo chủ đề

  useEffect(() => {
    localStorage.setItem("Address", null);
    localStorage.setItem("Waybills", null);
    localStorage.setItem("GetCSCode", null);
    localStorage.setItem("GetGoods", null);
    localStorage.setItem("GetPrice", null);
    localStorage.setItem("GetTime", null);
    localStorage.setItem("TrackBill", null);
    localStorage.setItem("Complain", null);
    localStorage.setItem("PastId", null);
    Task_spChatbotQuestion_List();
  }, []);

  useEffect(() => {
    setchatbottimer(
      window.setTimeout(() => {
        setLoadingStart("OK");
        if (dataAnswer.length > 0) {
          document.getElementById("ad").disabled = false;
        }
      }, 8000)
    );
  }, [dataAnswer]);

  const onChangeWarning = () => {
    setCustomerCodeStatus("Ok");
    setCustomerStatus("OK");
  };
  const onChangeWarning2 = () => {
    setOrderAddreStatus("Ok");
  };
  const onChangeWarning3 = () => {
    setOrderWeightStatus("Ok");
  };
  const onSelectWeight = (item) => {
    setWeightId2(item.value);
    setWeightName(item.label);
    setOrderDVC(item.label);
  };
  const AllSearch = async () => {
    let datachat = [...dataChat];
    if (
      (CustomerCode.trim() === "" ||
        CustomerCode.trim() === undefined ||
        CustomerCode.trim() === " ") &&
      (OrderPhone.trim() === "" ||
        OrderPhone.trim() === undefined ||
        OrderPhone.trim() === " ")
    ) {
      setCustomerStatus("NOTOK");
      setCustomerCode("");
      let ab = document.getElementById("code");
      ab.focus();
      return;
    }

    let ac = CheckPhone(CustomerCode);
    if (ac === "" || ac === undefined) {
      let ar = {
        CustomerCode: CustomerCode,
      };
      const params = {
        Json: JSON.stringify(ar),
        func: "CPN_spCustomer_CheckCode",
      };
      const list = await mainAction.API_spCallServerSystem(params, dispatch);

      if (list.Key === "NOTOK" || list.Status === "NOTOK") {
        setCustomerCodeStatus("NOTOK");
        let ab = document.getElementById("code");
        ab.focus();
        return;
      }
      if (list.Key !== "NOTOK" || list.Status !== "NOTOK") {
        setCustomerCodeStatus("OK");
      }
    }
    let checkadre = OrderAddre.trim().split(" ");
    if (
      OrderAddre.trim() === "" ||
      OrderAddre.trim() === undefined ||
      OrderAddre === " " ||
      checkadre.length < 5
    ) {
      setOrderAddre("");
      let ab = document.getElementById("address");
      ab.focus();
      setOrderAddreStatus("NOTOK");
      return;
    }

    let bb = "";
    if (isNaN(OrderWeight) === true) {
      bb = OrderWeight?.slice(0, 1);
    }

    if (
      +OrderWeight !== "" &&
      +OrderWeight !== undefined &&
      +OrderWeight !== 0 &&
      OrderWeight !== "0"
    ) {
      if (isNaN(OrderWeight) === true || bb === "0" || bb === 0) {
        Alertwarning("Vui lòng chỉ nhập số");
        setOrderWeight("");
        let ab = document.getElementById("weight");
        ab.focus();
        return;
      }
    }
    if (
      +OrderWeight < 0 ||
      +OrderWeight === 0 ||
      +OrderWeight === undefined ||
      +OrderWeight === "" ||
      +OrderWeight === "0" ||
      bb === "0"
    ) {
      setOrderWeight("");
      let ab = document.getElementById("weight");
      ab.focus();
      setOrderWeightStatus("NOTOK");
      return;
    }
    let pr = {
      CustomerCode: CustomerCode,
      CustomerPhone: CustomerCode,
      AddressName: OrderAddre,
      Mass: 0,
      Weight: OrderWeight,
    };

    localStorage.setItem("GetGoods", null);
    setStatusBtn("1");
    setdataChat(datachat);
    CPN_spCustomerOrder_Save(pr);
    setConfirmStatus("OUT");
    return;
  };
  const getWordBetweenTwoWords = (text, start, end) => {
    let startIndex = text.indexOf(start) + start.length;
    let endIndex = text.indexOf(end);
    let result = text.slice(startIndex, endIndex).trim();
    return result;
  };
  const getFrom = (data) => {
    let text = data.toLocaleLowerCase();
    let getdta = "";
    let endpoint = 0;
    let behindstr = "";
    let frlb = "";
    //let rg = new RegExp('/(?<=từ\s+).*?(?=\s+đến)/gs')

    // if (data.match(new RegExp('(?<=từ\s+).*?(?=\s+đến)gs')) !== null) {
    //   getdta = data.match(new RegExp('(?<=từ\s+).*?(?=\s+đến)gs'));
    //   endpoint = data.indexOf("đến");
    //   behindstr = data.substring(endpoint + 3);
    // }

    if (text.indexOf("từ") && text.indexOf("đến")) {
      getdta = getWordBetweenTwoWords(text, "từ", "đến");
      endpoint = text.indexOf("đến");
      behindstr = text.substring(endpoint + 3);
    }

    if (text.indexOf("từ") && text.indexOf("về")) {
      getdta = getWordBetweenTwoWords(text, "từ", "về");
      endpoint = text.indexOf("về");
      behindstr = text.substring(endpoint + 2);
    }

    if (text.indexOf("từ") && text.indexOf("đi")) {
      getdta = getWordBetweenTwoWords(text, "từ", "đi");
      endpoint = text.indexOf("đi");
      behindstr = text.substring(endpoint + 2);
    }

    if (text.indexOf("đi") && text.indexOf("từ")) {
      getdta = getWordBetweenTwoWords(data, "đi", "từ");
      endpoint = text.indexOf("đi");
      behindstr = text.substring(endpoint + 2);
    }

    if (text.indexOf("đi") && text.indexOf("về")) {
      getdta = getWordBetweenTwoWords(data, "đi", "về");
      endpoint = text.indexOf("đi");
      behindstr = text.substring(endpoint + 2);
    }

    // if (data.match(new RegExp('(?<=từ\s+).*?(?=\s+về)gs')) !== null) {
    //   getdta = data.match(new RegExp('(?<=từ\s+).*?(?=\s+về)gs'));
    //   endpoint = data.indexOf("về");
    //   behindstr = data.substring(endpoint + 2);
    // }
    // if (data.match(new RegExp('(?<=gửi\s+).*?(?=\s+từ)gs')) !== null) {
    //   getdta = data.match(new RegExp('(?<=từ\s+).*?(?=\s+về)gs'));
    //   endpoint = data.indexOf("về");
    //   behindstr = data.substring(endpoint + 2);
    // }
    // if (data.match(new RegExp('/(?<=từ\s+).*?(?=\s+đi)/gs')) !== null) {
    //   getdta = data.match(new RegExp('(?<=từ\s+).*?(?=\s+đi)gs'));
    //   endpoint = data.indexOf("đi");
    //   behindstr = data.substring(endpoint + 2);
    // }

    // if (data.match(new RegExp('/(?<=từ\s+).*?(?=\s+tới)/gs')) !== null) {
    //   getdta = data.match(new RegExp('/(?<=từ\s+).*?(?=\s+tới)/gs'));
    //   endpoint = data.indexOf("tới");
    //   behindstr = data.substring(endpoint + 3);
    // }
    // // if (data.match(new RegExp('/(?<=từ\s+).*?(?=\s+tới)/gs')) !== null) {
    // //   getdta = data.match(new RegExp('/(?<=từ\s+).*?(?=\s+tới)/gs'));
    // //   endpoint = data.indexOf("toi");
    // //   behindstr = data.substring(endpoint + 3);
    // // }

    // if (data.match(/(?<=từ\s+).*?(?=\s+về)/gs) !== null) {
    //   getdta = data.match(/(?<=từ\s+).*?(?=\s+về)/gs);
    //   endpoint = data.indexOf("ve");
    //   behindstr = data.substring(endpoint + 2);
    // }
    // if (data.match(/(?<=gửi\s+).*?(?=\s+từ)/gs) !== null) {
    //   getdta = data.match(/(?<=từ\s+).*?(?=\s+về)/gs);
    //   endpoint = data.indexOf("ve");
    //   behindstr = data.substring(endpoint + 2);
    // }

    // }
    if (data.match(/(.*)đến/) !== null) {
      getdta = data.match(/(.*)đến/);
      endpoint = data.indexOf("đến");
      behindstr = data.substring(endpoint + 3);
    }
    if (data.match(/(.*)đi/) !== null) {
      getdta = data.match(/(.*)đi/);
      endpoint = data.indexOf("đi");
      behindstr = data.substring(endpoint + 2);
    }
    if (data.match(/(.*)về/) !== null) {
      getdta = data.match(/(.*)về/);
      endpoint = data.indexOf("về");
      behindstr = data.substring(endpoint + 2);
    }

    let lcf = "";

    if (getdta) {
      lcf = CheckLocation(getdta[0], listState);
    }

    let frid = "";

    if (lcf) {
      frid = lcf.value;
      frlb = lcf.label;
    }

    let dtaback = [];

    const pr = {
      fid: frid,
      frdc: getdta,
      enpt: endpoint,
      str: behindstr,
      frlb: frlb,
    };
    dtaback.push(pr);
    return dtaback;
  };
  const Getgoods = (data) => {
    let info = "",
      endpoint = 0;

    if (data.match(new RegExp("dia chi(.*)")) !== null) {
      endpoint = data.indexOf("dia chi");
      info = data.substring(endpoint + 8);
      return info;
    }
    if (data.match(new RegExp("dia chi :(.*)")) !== null) {
      endpoint = data.indexOf("dia chi :");
      info = data.substring(endpoint + 10);
      return info;
    }
    if (data.match(new RegExp("dia chi:(.*)")) !== null) {
      endpoint = data.indexOf("dia chi:");
      info = data.substring(endpoint + 9);
      return info;
    }

    if (data.match(new RegExp("địa chỉ(.*)")) !== null) {
      endpoint = data.indexOf("địa chi");
      info = data.substring(endpoint + 8);
      return info;
    }
    if (data.match(new RegExp("Địa chỉ(.*)")) !== null) {
      endpoint = data.indexOf("Địa chỉ");
      info = data.substring(endpoint + 8);
      return info;
    }

    if (data.match(new RegExp("Địa Chỉ(.*)")) !== null) {
      endpoint = data.indexOf("Địa Chỉ");
      info = data.substring(endpoint + 8);
      return info;
    }

    if (data.match(new RegExp("Địa chỉ:(.*)")) !== null) {
      endpoint = data.indexOf("Địa chỉ:");
      info = data.substring(endpoint + 9);
      return info;
    }
    if (data.match(new RegExp("Địa chỉ :(.*)")) !== null) {
      endpoint = data.indexOf("Địa chỉ:");
      info = data.substring(endpoint + 10);
      return info;
    }

    if (data.match(new RegExp("đc(.*)")) !== null) {
      endpoint = data.indexOf("đc");
      info = data.substring(endpoint + 3);
      return info;
    }
    if (data.match(new RegExp("đc :(.*)")) !== null) {
      endpoint = data.indexOf("đc");
      info = data.substring(endpoint + 5);
      return info;
    }
    if (data.match(new RegExp("đc:(.*)")) !== null) {
      endpoint = data.indexOf("đc");
      info = data.substring(endpoint + 4);
      return info;
    }

    if (data.match(new RegExp("dc(.*)")) !== null) {
      endpoint = data.indexOf("dc");
      info = data.substring(endpoint + 3);
      return info;
    }
    if (data.match(new RegExp("/dc :(.*)/")) !== null) {
      endpoint = data.indexOf("dc :");
      info = data.substring(endpoint + 5);
      return info;
    }
    if (data.match(new RegExp("dc:(.*)")) !== null) {
      endpoint = data.indexOf("dc:");
      info = data.substring(endpoint + 4);
      return info;
    }
  };
  const Getphone = (data) => {
    let info = "",
      endpoint = 0;

    if (data.match(new RegExp("(.*)số điện thoại")) !== null) {
      endpoint = data.indexOf("số điện thoại");

      info = data.substring(endpoint + 13);
    }
    if (data.match(new RegExp("(.*)Số điện thoại")) !== null) {
      endpoint = data.indexOf("Số điện thoại");
      info = data.substring(endpoint + 13);
    }
    if (data.match(new RegExp("(.*)Số Điện Thoại")) !== null) {
      endpoint = data.indexOf("Số Điện Thoại");
      info = data.substring(endpoint + 13);
    }
    if (data.match(new RegExp("(.*)so dien thoai")) !== null) {
      endpoint = data.indexOf("so dien thoai");
      info = data.substring(endpoint + 13);
    }
    if (data.match(new RegExp("(.*)sđt")) !== null) {
      endpoint = data.indexOf("sđt");
      info = data.substring(endpoint + 3);
    }

    if (data.match(new RegExp("(.*)sđt :")) !== null) {
      endpoint = data.indexOf("sđt :");
      info = data.substring(endpoint + 5);
    }

    if (data.match(new RegExp("(.*)sđt:")) !== null) {
      endpoint = data.indexOf("sđt:");
      info = data.substring(endpoint + 4);
    }
    if (data.match(new RegExp("(.*)sdt")) !== null) {
      endpoint = data.indexOf("sdt");
      info = data.substring(endpoint + 3);
    }
    if (data.match(new RegExp("(.*)sdt :")) !== null) {
      endpoint = data.indexOf("sdt :");
      info = data.substring(endpoint + 5);
    }
    if (data.match(new RegExp("(.*)sdt:")) !== null) {
      endpoint = data.indexOf("sdt:");
      info = data.substring(endpoint + 4);
    }
    return info;
  };
  const CPN_spCustomerOrder_Save = async () => {
    if (OrderWeight > 25000 || OrderWeight === 25000) {
      setOrderMass(3);
    }

    let addre = ClearAdrress(OrderAddre);
    let trimaddress = addre.trim().split(" ");
    let arrayadd = [];

    trimaddress.forEach((e, index) => {
      let cr = {
        Name: e,
        STT: index + 1,
      };
      arrayadd.push(cr);
    });
    let addresscheck = await CheckAddress(arrayadd);
    let addresscheck2 = await CheckAddress2(addre);

    // console.log('aaaaa',addresscheck,addresscheck2)

    let dta = "";
    if (addresscheck.length > 0) {
      dta = CheckAddressFinal(addre, addresscheck);
    } else {
      dta = CheckAddressFinal(addre, addresscheck2);
    }

    if (addresscheck.length === 0 && addresscheck2.length === 0) {
      // console.log('hdlanljd',dta)

      let textagain =
        "Địa chỉ ko đúng định dạng. Anh/Chị vui lòng nhập lại địa chỉ đúng";
      setError(textagain);
      setOrderAddreStatus("NOTOK");
      setConfirmStatus("OK");
      return;
    }
    let qdkl = CheckUnit(OrderDVC, OrderWeight)?.Text;

    let pr = {
      CustomerCode: CustomerCode,
      CustomerPhone: CustomerCode?.replace(
        /[`~!#$^&*()_|+\=;'",.<>\{\}\[\]\\\/]/gi,
        ""
      ),
      AddressName: OrderAddre,
      Mass: OrderMass,
      Notes: OrderNote?.replace(/[`~!#$^&*()_|+\-=;'",.<>\{\}\[\]\\\/]/gi, ""),
      Lat: Lat !== "" && Lat !== undefined ? Lat : "",
      Lng: Long !== "" && Long !== undefined ? Long : "",
      ProvinceID: dta?.CityId,
      DistrictId: dta?.DistrictId,
      WardId: dta?.WardId,
      Weight: qdkl,
      ProvinceName: addresscheck[0]?.CityName,
      DistrictName: addresscheck[0]?.DistrictName,
      WardName: addresscheck[0]?.WardName,
    };

    let datachat = [...dataChat];
    const params = {
      Json: JSON.stringify(pr),
      func: "CPN_spCustomerOrder_Save_API",
      API_key: "netcoApikey2025",
    };
    try {
      const list = await mainAction.API_spCallServerSystem(params, dispatch);

      if (list.Status === "NOTOK1") {
        setConfirmStatus("OK");
        // setOrderAddre("");
        setError(list.ReturnMess);
        let ab = document.getElementById("address");
        ab.focus();
        setOrderAddreStatus("NOTOK");

        return;
      }

      if (list.Status === "OK") {
        setCustomerCode("");
        setStatus("Out");
        setConfirmStatus("OUT");
        setOrderNote("");

        let pk = {
          Key: "NETCO",
          Result:
            (list.Name !== "" && list.Name !== undefined
              ? "Dạ yêu cầu của " +
                Gender.toLocaleLowerCase() +
                " " +
                "đã được xử lý .Tài xế sẽ tới lấy hàng trong 30 phút nữa" +
                "<br/>"
              : "Dạ yêu cầu của " +
                Gender.toLocaleLowerCase() +
                " " +
                "đã được xử lý.Sẽ có nhân viên liên hệ với " +
                Gender.toLowerCase() +
                " để xác nhận lại thông tin ." +
                Gender +
                " vui lòng chờ trong ít phút" +
                "<br/>") +
            (list.Name !== "" && list.Name !== undefined
              ? "Nhân viên lấy hàng :" + " " + list.Name + "<br/>"
              : "Nhân viên lấy hàng :" + " Chưa cài đặt tuyến " + "<br/>") +
            (list.Phone !== "" && list.Phone !== undefined
              ? "Số điện thoại tài xế : " + list.Phone + "<br/>"
              : "") +
            (list.VehicleNameHold !== "" && list.VehicleNameHold !== undefined
              ? "Biển số xe : " + list.VehicleNameHold + "<br/>"
              : "") +
            "Địa chỉ :" +
            " " +
            pr.AddressName +
            "<br/>" +
            "Notes :" +
            " " +
            list?.Notes +
            "<br/>" +
            (list.CustomerCode !== "" && list.CustomerCode !== undefined
              ? "Mã KH : " + list.CustomerCode + "<br/"
              : "") +
            "Số điện thoại:" +
            " " +
            pr.CustomerPhone +
            "<br/>" +
            Gender +
            " " +
            "vui lòng kiểm tra điện thoại để nhận cuộc gọi tới từ tài xế" +
            "<br/>" +
            "Cám ơn" +
            " " +
            Gender.toLocaleLowerCase() +
            " đã tin dùng dịch vụ của Netco",
          Keys: 0,
        };
        Task_spChatbot_LogMessage_Save(
          idbot,
          id,
          pk.Result?.replace(/(<([^>]+)>)/gi, ""),
          NameBot,
          NameKh,
          649
        );
        const dataview = [...datachat];
        dataview.push(pk);

        setdataChat(dataview);
        const GetGoods = await localStorage.getItem("GetGoods");
        localStorage.setItem("GetGoods", null);
        let order = document.getElementById("OrderBtn");
        order?.classList.add("fade");
        //xóa câu hỏi cũ
        setQuestion("");
        setChatKH("");
        CreateOrder_Clear();
        setStatus("Out");
        setConfirmStatus("OK");
        return;
      }

      if (list.Status === "NOTOK") {
        setCustomerCode("");
        setStatus("Out");
        setConfirmStatus("OK");
        setOrderNote("");

        let pk = {
          Key: "NETCO",
          Result:
            "Dạ yêu cầu của " +
            Gender.toLocaleLowerCase() +
            " " +
            "đã được tiếp nhận .Sẽ có nhân viên liên hệ lại với " +
            Gender.toLowerCase() +
            " để xác nhận lại thông tin ." +
            Gender +
            " vui lòng chờ trong ít phút" +
            "<br/>" +
            "Địa chỉ :" +
            " " +
            pr.AddressName +
            "<br/>" +
            "Notes :" +
            " " +
            OrderNote +
            "<br/>" +
            "Số điện thoại:" +
            " " +
            pr.CustomerPhone +
            "<br/>" +
            Gender +
            " " +
            "vui lòng kiểm tra điện thoại để nhận cuộc gọi tới từ nhân viên hỗ trợ ." +
            "<br/>" +
            "Cám ơn" +
            " " +
            Gender.toLocaleLowerCase() +
            " đã tin dùng dịch vụ của Netco",
          Keys: 0,
        };
        const dataview = [...datachat];
        dataview.push(pk);

        setdataChat(dataview);
        localStorage.setItem("GetGoods", null);
        let order = document.getElementById("OrderBtn");
        order?.classList.add("disabled");
        //xóa câu hỏi cũ
        setQuestion("");
        setChatKH("");
        CreateOrder_Clear();
        return;
      }
    } catch (err) {
      const dataview = [...datachat];
      let error = {
        Key: "NETCO",
        Result:
          "Hệ thống Netco đang cập nhập vui lòng truy cập lại sau. Xin cám ơn!",
        Keys: 0,
      };
      dataview.push(error);
      setdataChat(dataview);
      localStorage.setItem("GetGoods", null);
      //xóa câu hỏi cũ
      setQuestion("");
      setChatKH("");
      CreateOrder_Clear();
      console.log("error", err);
      return;
    }
  };
  const TrackingPrice = async (pricedetail, qdkl) => {
    let data = [...dataChat];
    let pr = {
      CustomerId: 0,
      CityGoId: pricedetail.StartPoint,
      CityToId: pricedetail.ToPoint,
      DistrictTo: 279,
      WardToId: 0,
      Weight: qdkl !== 0 ? qdkl : 1000,
      Mass: 0,
      Number: 0,
      HHKG: 0,
      COD: 0,
    };

    const params = {
      Json: JSON.stringify(pr),
      func: "CPN_spLading_EstimatesPrice",
    };
    const list = await mainAction.API_spCallServerSystem(params, dispatch);
    let date = new Date();
    const newdate = new Date();
    newdate.setDate(date.getDate() + 1);

    let listM = "";
    let service = "";
    const listmoney = list.filter(
      (p) => p.ServiceName !== "My NETCO" && p.ServiceName !== "Phát hỏa tốc"
    );
    listmoney.forEach((element) => {
      if (element.Code === "Chuyển phát nhanh") {
        service = "Nhanh";
      }
      if (element.Code === "Chuyển phát đường bộ") {
        service = "Tiết kiệm";
      }
      if (element.Code === "Phát trước 9h") {
        service = "Phát 9h";
      }
      if (element.Code === "Phát trước 12h") {
        service = "Phát 12h";
      }
      if (element.Code === "Phát trước 16h") {
        service = "Phát 16h";
      }
      listM +=
        '<div className="col-md-12" style={{ width: "100%", marginTop: "30px" }}>' +
        service +
        " :" +
        '<h style="color :#1bbb7b">' +
        (element.Amount === 0
          ? "Hiện tại không khả dụng"
          : FormatMoney(element.Amount) + " vnđ") +
        "</h>" +
        "<br/>" +
        (element.Amount !== 0
          ? "=> Dự kiến giao ngày :" +
            '<h style="color :#1bbb7b">' +
            (element.DealineTime === "" || element.DealineTime === undefined
              ? FormatDateJson(newdate)
              : FormatDateJson(element.DealineTime)) +
            "</h>" +
            "<br/> "
          : "") +
        "</div>" +
        "<br/>";
    });

    let chk = {
      Key: "NETCO",
      Result:
        "Dạ vâng đây ước tính cước cho các dịch vụ vận chuyển để đáp ứng nhu cầu của" +
        " " +
        "<br/>" +
        listM +
        '<h style="color:red">' +
        Gender +
        " " +
        "lưu ý đây là cước phí ước tính chưa bao gồm phí phát ngoại tuyến, VAT và phụ phí xăng dầu nha." +
        "</h>" +
        "<br/>" +
        +"<br/>",
      Keys: 0,
    };
    Task_spChatbot_LogMessage_Save(idbot, id, "Tra giá", NameBot, NameKh, 18);
    localStorage.setItem("GetPrice", null);
    const dataview = [...data];
    dataview.push(chk);
    let loading = document.getElementById("loading");
    loading.classList.add("fade");
    setdataChat(dataview);
    //xóa câu hỏi cũ
    setQuestion("");
    setChatKH("");
    setPriceDetail("");
    return;
  };
  const onSendNofity = async (functionsend) => {
    const NotifiParam = {
      Json: JSON.stringify({
        CustomerId: 0,
        FuncSend: functionsend,
        SendFrom: "WEBSITE NETCO.COM.VN",
        JsonData: [
          {
            Name: NameKh,
            Phone: Phone,
            Content: "Khách hàng kiếu nại",
          },
        ],
      }),
      func: "APIC_spSendNotification",
      API_key: APIKey,
    };
    const resultNotify = await mainAction.API_spCallServerSystem(
      NotifiParam,
      dispatch
    );
    if (resultNotify.length > 0) return;
  };

  /*Phân tích và xử lý câu hỏi*/
  // xử lí câu hỏi
  const ActionChat = async (e, data) => {
    try {
      let datasearch = [];
      let entity = [];

      let text = e?.Result.toLowerCase();
      let checktext = text
        ?.replace(",", " ")
        .trim()
        .split(" ")
        .map((element) => element.trim())
        .filter((element) => element !== "");

      if (checktext.length > 20 && text?.includes("lấy hàng") === false) {
        const viewmsg = {
          Key: "NETCO",
          Result:
            "Dạ rất xin lỗi  " +
            Gender.toLocaleLowerCase() +
            ", em chưa hiểu được cụ thể yêu cầu ạ " +
            Gender +
            " vui lòng gửi lại yêu cầu ngắn gọn hơn giúp em nhé!",
          Keys: 0,
        };

        const dataview = [...data];
        dataview.push(viewmsg);
        let loading = document.getElementById("loading");
        loading.classList.add("fade");
        setdataChat(dataview);
        setQuestion("");

        setChatKH("");
        return;
      }

      if (Array.length > 0) {
        datasearch = [...Array];
        entity = CheckResult2(e?.Result, Array);
      } else {
        datasearch = [...dataAnswer];
        entity = CheckResult(e?.Result, dataAnswer);
      }

      // kiểm tra câu hỏi gần nhất có liên quan đén hỏi thông tin đơn hàng không?

      let dtarandom = dataRandom.filter((p) => p.SubjectName === "Respond");
      let randomtxt =
        "Dạ Anh/Chị vui lòng để lại số điện thoại em sẽ cho các bạn CSKH liên hệ lại cho Anh/Chị nha";
      let random = dtarandom[Math.floor(Math.random() * dataRandom.length)];
      if (random) {
        randomtxt = random.Message;
      }

      localStorage.setItem("PastId", entity?.SubjectId);
      setSubjectId(entity?.SubjectId);
      // lấy hàng
      const keysp = false;

      if (entity !== "" && entity !== undefined) {
        let checkgetgoods = CheckOrder(e.Result, GetGoods);
        let checkcomplain = CheckGoods(e.Result, GetComplain);
        let checkprrice = CheckGoods(e.Result, GetPrices);
        let checktime = CheckGoods(e.Result, GetTimes);
        let checkbill = CheckGoods(e.Result, GetBill);
        let getgoods = await localStorage.getItem("GetGoods");
        let complain = await localStorage.getItem("Complain");
        let getbill = await localStorage.getItem("TrackBill");
        let GetPrice = await localStorage.getItem("GetPrice");
        let GetTime = await localStorage.getItem("GetTime");
        //lấy mã kh
        if (
          entity?.SubjectId === 7 ||
          (checkgetgoods !== undefined && checkgetgoods?.Percet > 20) ||
          getgoods !== "null"
        ) {
          let dataOrder = "";
          dataOrder += OrderData + e.Result;

          let data = await CreateOrder(dataOrder);

          if (data !== "" && data !== undefined) {
            if (
              (data?.CustomerCode === "" || data?.CustomerCode === undefined) &&
              data?.Phone !== ""
            ) {
              setCustomerCode(data?.Phone.trim());
            } else {
              setCustomerCode((data?.CustomerCode).trim());
            }
            setOrderAddre(data?.Address.trim());
            if (data?.Weight === NaN) {
              setOrderWeight("");
            } else {
              setOrderWeight(data?.Weight);
            }
            setOrderMass(data?.Mass);
            setDistrict(data?.ProvinceID);
            setOrderDVC(data?.Dvc);
          }
          let loading = document.getElementById("loading");
          loading.classList.add("fade");
          setStatus("OK");
          return;
        }
        //tra giá

        if (
          entity?.SubjectId === 18 ||
          (checkprrice !== undefined &&
            checkprrice?.Percet > 40 &&
            CheckInfor(e.Result) === true) ||
          GetPrice !== "null"
        ) {
          let dataChatPrices = "";
          dataChatPrices += PriceDetail + e.Result;
          setPriceDetail(dataChatPrices);
          localStorage.setItem("GetPrice", "_ok_");
          setStatus("Out");
          CreateOrder_Clear();

          let pricedetail = CreatePrices(dataChatPrices.toLowerCase());
          let bb = CreatePrices(e.Result.toLowerCase());

          if (
            checkprrice === undefined &&
            bb.StartPoint === "" &&
            bb.ToPoint === "" &&
            bb.Weight === 0
          ) {
            let ar = CheckResult(e.Result, dataAnswer);
            const viewmsg = {
              Key: "NETCO",
              Result:
                ar.Percet < 50
                  ? randomtxt.replaceAll("Anh/Chị", Gender)
                  : ar.ChatbotAnswer.replaceAll("Anh/Chị", Gender).replaceAll(
                      "A/C",
                      Gender
                    ),
              Keys: 0,
            };

            // lưu câu hỏi gần nhất
            const dataview = [...data];
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            dataview.push(viewmsg);
            localStorage.setItem("GetGoods", null);
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              viewmsg.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              18
            );
            setdataChat(dataview);
            TheEnd(dataview);
            setPriceDetail("");
            return;
          }

          if (
            (pricedetail.StartPoint === "" ||
              pricedetail.StartPoint === undefined ||
              pricedetail.ToPoint === "" ||
              pricedetail.ToPoint === undefined) &&
            (pricedetail.Weight === 0 ||
              pricedetail.Weight === undefined ||
              pricedetail.Weight === "" ||
              pricedetail.Weight === NaN)
          ) {
            let chk = {
              Key: "NETCO",
              Result:
                "Dạ để tra cước phí " +
                Gender.toLocaleLowerCase() +
                " vui lòng cung cấp cho em thông tin sau nha" +
                "<br/>" +
                "Tỉnh đi và tỉnh đến : từ Hồ Chí Minh về Hà Nội" +
                "<br/>" +
                "Trọng lượng đơn hàng : 20kg" +
                "<br/>",
              Keys: 0,
            };
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              chk.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              18
            );

            localStorage.setItem("GetPrice", "_ok_");
            const dataview = [...data];
            dataview.push(chk);
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            setdataChat(dataview);
            //xóa câu hỏi cũ
            setQuestion("");
            setChatKH("");
            return;
          }

          if (
            pricedetail.StartPoint === "" ||
            pricedetail.StartPoint === undefined ||
            pricedetail.ToPoint === "" ||
            pricedetail.ToPoint === undefined
          ) {
            let chk = {
              Key: "NETCO",
              Result:
                "Dạ để tra cước phí  " +
                Gender.toLocaleLowerCase() +
                " vui lòng cung cấp cho em thông tin tỉnh đi và tỉnh dến theo mẫu sau : " +
                "<br/>" +
                "=>  từ Hồ Chí Minh về Hà Nội " +
                "<br/>",

              Keys: 0,
            };
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              chk.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              18
            );
            const price = await localStorage.getItem("GetPrice");
            localStorage.setItem("GetPrice", "_ok_");
            const dataview = [...data];
            dataview.push(chk);
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            setdataChat(dataview);
            //xóa câu hỏi cũ
            setQuestion("");
            setChatKH("");
            return;
          }

          let qdkl = CheckUnit(pricedetail.DVC, pricedetail.Weight)?.Text;

          if (
            pricedetail.Weight === 0 ||
            pricedetail.Weight === undefined ||
            pricedetail.Weight === "" ||
            pricedetail.Weight === NaN
          ) {
            let chk = {
              Key: "NETCO",
              Result:
                "Dạ để tra cước phí  " +
                Gender.toLocaleLowerCase() +
                " vui lòng cung cấp cho em thông tin trọng lượng đơn hàng : " +
                "<br/>" +
                "=> 20 kg " +
                "<br/>",

              Keys: 0,
            };
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              chk.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              18
            );
            const price = await localStorage.getItem("GetPrice");
            localStorage.setItem("GetPrice", "_ok_");
            const dataview = [...data];
            dataview.push(chk);
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            setdataChat(dataview);
            //xóa câu hỏi cũ
            setQuestion("");
            setChatKH("");
            return;
          }

          let confirminfor = {
            Key: "NETCO",
            Result:
              "Dạ vâng " +
              Gender +
              " vui lòng kiểm tra lại các thông tin và xác nhận giúp em " +
              "<br/>" +
              "Tỉnh đi : " +
              pricedetail?.StartLabel +
              "<br/>" +
              "Tỉnh đến :" +
              pricedetail?.ToLabel +
              "<br/>" +
              "Trọng lượng :" +
              qdkl,
            Key: 0,
          };

          Task_spChatbot_LogMessage_Save(
            idbot,
            id,
            confirminfor.Result?.replace(/(<([^>]+)>)/gi, ""),
            NameBot,
            NameKh,
            18
          );
          TrackingPrice(pricedetail, qdkl);
          setPriceCheck(pricedetail);
          setPriceWeight(qdkl);
          localStorage.setItem("GetPrice", null);
          const dataview = [...data];
          dataview.push(confirminfor);

          // let aka = {
          //   Key: "TrackingPrice",
          //   Key:0
          //             }
          // dataview.push(aka);
          let loading = document.getElementById("loading");
          loading.classList.add("fade");
          setdataChat(dataview);
          //xóa câu hỏi cũ
          setQuestion("");
          setChatKH("");
          return;
        }

        // tra thời gian
        if (
          entity?.SubjectId === 19 ||
          (checktime !== undefined && checktime?.Percet > 40) ||
          GetTime !== "null"
        ) {
          let dataChatTime = "";
          dataChatTime += TimeDetail + e.Result;
          setTimeDetail(dataChatTime);
          setStatus("Out");
          setStatusBtn("0");
          CreateOrder_Clear();
          let timedetail = CheckTimes(dataChatTime.toLowerCase());
          let bb = CheckTimes(e.Result.toLowerCase());

          if (
            checktime === undefined &&
            bb.StartPoint === "" &&
            bb.ToPoint === 0
          ) {
            let ar = CheckResult(e.Result, dataAnswer);
            const viewmsg = {
              Key: "NETCO",
              Result:
                ar.Percet < 50
                  ? randomtxt.replaceAll("Anh/Chị", Gender)
                  : ar.ChatbotAnswer.replaceAll("Anh/Chị", Gender).replaceAll(
                      "A/C",
                      Gender
                    ),
              Keys: 0,
            };

            // lưu câu hỏi gần nhất
            const dataview = [...data];
            localStorage.setItem("NearAsk", ar?.Result);
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            dataview.push(viewmsg);
            localStorage.setItem("GetTime", null);
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              viewmsg.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              19
            );
            setdataChat(dataview);
            TheEnd(dataview);
            setTimeDetail("");
            return;
          }

          if (
            timedetail.StartPoint === "" ||
            timedetail.StartPoint === undefined ||
            timedetail.ToPoint === "" ||
            timedetail.ToPoint === undefined
          ) {
            let chk = {
              Key: "NETCO",
              Result:
                "Dạ để xem thời gian dự kiến giao " +
                Gender.toLocaleLowerCase() +
                " vui lòng cung cấp cho em thông tin theo mẫu sau :" +
                "<br/>" +
                "Tỉnh đi và đến : Từ Hồ Chí Minh về Hà Nội" +
                "<br/>",

              Keys: 0,
            };
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              chk.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              19
            );
            localStorage.setItem("GetTime", "_ok_");
            const dataview = [...data];
            dataview.push(chk);
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            setdataChat(dataview);
            //xóa câu hỏi cũ
            setQuestion("");
            setChatKH("");
            return;
          }

          let pr = {
            CustomerId: 0,
            CityGoId: timedetail.StartPoint,
            CityToId: timedetail.ToPoint,
            DistrictTo: 279,
            WardToId: 0,
            Weight: 1000,
            Mass: 0,
            Number: 0,
            HHKG: 0,
            COD: 0,
          };

          const params = {
            Json: JSON.stringify(pr),
            func: "CPN_spLading_EstimatesPrice",
          };
          const list = await mainAction.API_spCallServerSystem(
            params,
            dispatch
          );

          let date = new Date();
          const newdate = new Date();
          newdate.setDate(date.getDate() + 1);

          let listM = "";
          let service = "";

          const listmoney = list.filter(
            (p) =>
              p.ServiceName !== "My NETCO" && p.ServiceName !== "Phát hỏa tốc"
          );
          listmoney.forEach((element) => {
            if (element.Code === "Chuyển phát nhanh") {
              service = "Nhanh";
            }
            if (element.Code === "Chuyển phát đường bộ") {
              service = "Tiết kiệm";
            }
            if (element.Code === "Phát trước 9h") {
              service = "Phát 9h";
            }
            if (element.Code === "Phát trước 12h") {
              service = "Phát 12h";
            }
            if (element.Code === "Phát trước 16h") {
              service = "Phát 16h";
            }
            listM +=
              '<div className="col-md-12" style={{ width: "100%", marginTop: "30px" }}>' +
              service +
              " :" +
              '<h style="color :#1bbb7b">' +
              (element.DealineTime === "" || element.DealineTime === undefined
                ? FormatDateJson(newdate)
                : FormatDateJson(element.DealineTime)) +
              "</h>" +
              "<br/> " +
              "</div>" +
              "<br/>";
          });

          let chk = {
            Key: "NETCO",
            Result:
              "Dạ vâng đây thời gian giao hàng dự kiến cho các dịch vụ vận chuyển để đáp ứng nhu cầu của" +
              " " +
              Gender.toLowerCase() +
              " " +
              "ạ" +
              "<br/>" +
              "<br/>" +
              listM +
              '<a className="btn btn-success btn-sm pd-30 mf-2" href="https://customer.netco.com.vn">' +
              "Tạo đơn ngay" +
              "</a>",
            Keys: 0,
          };
          Task_spChatbot_LogMessage_Save(
            idbot,
            id,
            "Tra thời gian",
            NameBot,
            NameKh,
            19
          );
          localStorage.setItem("GetTime", null);
          const dataview = [...data];
          dataview.push(chk);
          let loading = document.getElementById("loading");
          loading.classList.add("fade");
          setdataChat(dataview);

          //xóa câu hỏi cũ
          setQuestion("");
          setChatKH("");
          setTimeDetail("");
          return;
        }

        // khieu nai

        if (
          entity?.SubjectId === 6 ||
          complain !== "null" ||
          (checkcomplain !== undefined && checkcomplain.Percet > 40)
        ) {
          if (ComplainStatus === 1) {
            const viewmsg = {
              Key: "NETCO",
              Result:
                "Em rất xin lỗi về vấn đề " +
                Gender +
                " đang gặp phải. " +
                Gender +
                " có thể cung cấp cho em số điện thoại và vấn đề mà " +
                Gender.toLocaleLowerCase() +
                " muốn khiếu nại được không ạ?",
              Keys: 0,
            };

            const dataview = [...data];
            dataview.push(viewmsg);
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            setdataChat(dataview);
            setComplainStatus(2);
            localStorage.setItem("Complain", "aaaa");
            onSendNofity("ComplainCreate");
            //xóa câu hỏi cũ
            setQuestion("");
            setChatKH("");
            return;
          }
        }

        //tra bill
        if (
          entity?.SubjectId === 3 ||
          (checkbill !== undefined && checkbill?.Percet > 40) ||
          getbill !== "null"
        ) {
          let dataChatBill = "";
          dataChatBill += BillDetail + e.Result;
          setBillDetail(dataChatBill);
          setStatus("Out");
          setStatusBtn("0");

          let waybillAll = CheckWaybill(dataChatBill);
          let waybill = CheckWaybill(e.Result);
          let endpoint = dataChatBill.indexOf("code&docode=");
          let behindstr = dataChatBill.substring(endpoint + 12);
          let bill = "";
          behindstr !== "" &&
          behindstr !== undefined &&
          behindstr.length > 8 &&
          dataChatBill.includes("http")
            ? (bill = behindstr)
            : (bill = "");

          if (
            (waybill === undefined || waybill === "") &&
            (checkbill === undefined || checkbill === "") &&
            bill === "" &&
            bill.length > 8
          ) {
            let ar = CheckResult(e.Result, datasearch);
            const viewmsg = {
              Key: "NETCO",
              Result:
                ar.Percet < 50
                  ? randomtxt.replaceAll("Anh/Chị", Gender)
                  : ar.ChatbotAnswer.replaceAll("Anh/Chị", Gender).replaceAll(
                      "A/C",
                      Gender
                    ),
              Keys: 0,
            };

            // lưu câu hỏi gần nhất
            const dataview = [...data];
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            dataview.push(viewmsg);
            localStorage.setItem("TrackBill", null);
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              viewmsg.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              entity?.SubjectId
            );
            setdataChat(dataview);
            TheEnd(dataview);
            setBillDetail("");
            return;
          }

          let Cwaybillold = await localStorage.getItem("Waybills");
          if (Cwaybillold.includes(waybillAll)) waybillAll = "";

          if (Cwaybillold.includes(waybillAll) && waybillAll !== "") {
            const viewmsgold = {
              Key: "NETCO",
              Result:
                "Vận đơn " +
                '<h style="color :#1bbb7b">' +
                waybillAll.toUpperCase() +
                "</h>" +
                " này em đã trả lời thông tin bên trên rồi ạ ? (A/C có thể liên hệ trực tiếp với tổng đài 19000328 - 024.99999.0328 )",
              Keys: 0,
            };

            // lưu câu hỏi gần nhất
            const dataviewold = [...data];
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            dataviewold.push(viewmsgold);
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              viewmsgold.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              3
            );
            setdataChat(dataviewold);
            return;
          }
          let ladingcode = "";
          waybillAll !== "" && waybillAll !== undefined
            ? (ladingcode = waybillAll)
            : (ladingcode = bill);

          if (ladingcode === "" || ladingcode === undefined) {
            let chk = {
              Key: "NETCO",
              Result:
                "Vâng " +
                Gender.toLocaleLowerCase() +
                " vui lòng cho em xin thông tin mã vận đơn " +
                "viết liền không khoảng trắng hoặc dấu chấm ạ :" +
                "<br/>" +
                "Vd: Tra vận đơn BDG110223001954  " +
                "<br/>",
              Keys: 0,
            };
            localStorage.setItem("TrackBill", "bill");
            const dataview = [...data];
            dataview.push(chk);
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            setdataChat(dataview);
            localStorage.setItem("TrackBill", null);
            setBillDetail("");
            //xóa câu hỏi cũ
            setQuestion("");
            setChatKH("");
            return;
          }
          const params = {
            Json: '{"KeySearch":"' + ladingcode + '"}',
            func: "Task_spChatbot_TrackingBill",
          };

          const list = await mainAction.API_spCallServerSystem(
            params,
            dispatch
          );
          const datareturn = list;
          const today = new Date();

          if (datareturn[0] !== "" && datareturn[0] !== undefined) {
            let cat = e.Result;
            let status = datareturn[0].StatusId;

            if (
              cat.includes("bao lâu giao") ||
              cat.includes("bao giờ giao ") ||
              cat.includes("khi nào giao") ||
              cat.includes("lúc nào thì giao được")
            ) {
              let a = "Chưa có thông tin";

              if (datareturn[0].DealineTime) {
                a = FormatDateJson(datareturn[0].DealineTime);
              }

              let chk = {
                Key: "NETCO",
                Result:
                  "Dạ đơn hàng của" +
                  " " +
                  Gender.toLowerCase() +
                  " " +
                  "dự kiến giao vào ngày :" +
                  '<h style="color :#1bbb7b">' +
                  a +
                  "</h>",
                Keys: 0,
              };
              Task_spChatbot_LogMessage_Save(
                idbot,
                id,
                "tra cứu ngày giao",
                NameBot,
                NameKh,
                3
              );
              const dataview = [...data];
              dataview.push(chk);
              let loading = document.getElementById("loading");
              loading.classList.add("fade");
              setdataChat(dataview);
              localStorage.setItem("TrackBill", null);
              //xóa câu hỏi cũ
              setQuestion("");
              setChatKH("");
              setBillDetail("");
              return;
            }

            let dealtime = "";
            if (datareturn[0].DealineTime > today && status !== 6) {
              dealtime = today.getDate() + 1;
            }

            let a = "Chưa có thông tin";

            if (status !== 6) {
              const NotifiParam = {
                Json: JSON.stringify({
                  FuncSend: "SendNotifyCS",
                  SendFrom: "WEBSITE NETCO.COM.VN",
                  JsonData: [
                    {
                      LadingCode: ladingcode,
                      Name: NameKh,
                      Phone: Phone,
                    },
                  ],
                }),
                func: "APIC_spSendNotification",
              };

              const resultNotify = await mainAction.API_spCallServerSystem(
                NotifiParam,
                dispatch
              );
            }

            let ResultBill = "";
            datareturn.forEach((element) => {
              ResultBill +=
                '<div className="col-md-12" style={{ width: "100%", marginTop: "30px" }}>' +
                I18n.t("Chat.YesWaybill") +
                '<h style="color :#1bbb7b">' +
                element.Code.toUpperCase() +
                "</h>" +
                "<br/>" +
                I18n.t("Chat.WaybillStatus") +
                '<h style="color :#1bbb7b">' +
                element.StatusName +
                "</h>" +
                "<br/>" +
                I18n.t("Chat.CustomerRate") +
                element.CustomerRate +
                "<br/>" +
                I18n.t("Chat.ServiceInfo") +
                '<h style="color :#1bbb7b">' +
                element.ServiceName +
                "</h>" +
                "<br/>" +
                (element.StatusId === 6
                  ? I18n.t("Chat.RealityRecipient") +
                    element.Recipient_reality +
                    "<br/>"
                  : "") +
                (element.StatusId !== 6
                  ? I18n.t("Chat.DealineTime") +
                    '<h style="color :#FF9933">' +
                    (element.DealineTime === "" ||
                    element.DealineTime === undefined
                      ? a
                      : FormatDateJson(element.DealineTime)) +
                    "</h>" +
                    "<br/> "
                  : "") +
                (element.StatusId === 6
                  ? I18n.t("Chat.DateOfReceipt") +
                    '<h style="color :#FF9933">' +
                    FormatDateJson(element.FinishDate) +
                    "</h>" +
                    "<br/>"
                  : "") +
                I18n.t("Chat.CSSInformation") +
                element.CustomerServiceInfor +
                "<br/>" +
                (element.COD > 0
                  ? I18n.t("Chat.COD") + element.COD + "  " + "vnd" + "<br/>"
                  : "") +
                "</div>" +
                "Hình ảnh chứng nhận giao bill :";

              if (element.StatusId === 6) {
                ResultBill +=
                  '<div className="col-md-12" style={{ width: "100%", marginTop: "30px" }}>';

                ResultBill += '<div className="row">';

                ResultBill += '<div className="col-md-6" >';
                {
                  if (
                    element.ImageDeliveryList !== undefined &&
                    element.ImageDeliveryList !== "" &&
                    element.ImageDeliveryList !== null
                  )
                    JSON.parse(element.ImageDeliveryList).map((img, index) => {
                      ResultBill +=
                        "<p>" +
                        '<h style="color :#1bbb7b">' +
                        "* Hình ảnh giao bill thành công" +
                        "</h>" +
                        "</p>";
                      ResultBill +=
                        "<a href=" +
                        img.splitdata +
                        ' target="_blank" rel="noreferrer">';
                      ResultBill +=
                        '<img src="' +
                        img.splitdata +
                        '"  style={{ width: "100%", display: "block" }} />';
                      ResultBill += "</a>";
                    });
                }
                ResultBill += "</div>";
                ResultBill += "<br/>";
                ResultBill += '<div className="col-md-6">';
                {
                  if (
                    element.SetUpImage !== undefined &&
                    element.SetUpImage !== "" &&
                    element.SetUpImage !== null
                  )
                    JSON.parse(element.SetUpImage).map((img, index) => {
                      ResultBill +=
                        "<p>" +
                        '<h style="color :#1bbb7b">' +
                        "* Hình ảnh lắp đặt" +
                        "</h>" +
                        "</p>";
                      ResultBill +=
                        "<a href=" +
                        img.splitdata +
                        ' target="_blank" rel="noreferrer">';
                      ResultBill +=
                        '<img src="' +
                        img.splitdata +
                        '"  style={{ width: "100%", display: "block" }} />';
                      ResultBill += "</a>";
                    });
                }
                ResultBill += "</div>";
                ResultBill += "</div>";
                ResultBill +=
                  "</div>" +
                  '<h style="color :red">' +
                  "*    " +
                  "</h>" +
                  Gender +
                  " " +
                  " có thể truy cập vào đường link :" +
                  '<a href="https://netco.com.vn/tra-cuu-don-hang?code=' +
                  ladingcode +
                  '">' +
                  '<h style="color :#0066FF">' +
                  "https://netco.com.vn/tra-cuu-don-hang?code=" +
                  ladingcode +
                  "</h>" +
                  "</a>" +
                  "để biết thêm thông tin chi tiết!!!" +
                  "<br/>";
              }
            });

            // trường hợp tìm thấy đơn
            let chk = {
              Key: "NETCO",
              Result: ResultBill,
              Keys: 0,
            };
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              "Tra cứu vận đơn",
              NameBot,
              NameKh,
              3
            );
            const dataview = [...data];
            dataview.push(chk);
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            setdataChat(dataview);
            localStorage.setItem("TrackBill", null);
            setBillDetail("");
            //xóa câu hỏi cũ
            setQuestion("");
            setChatKH("");
            const waybillold = await localStorage.getItem("Waybills");
            localStorage.setItem(
              "Waybills",
              waybillold === "null"
                ? "_ok_" + ladingcode
                : waybillold + "_ok_ " + ladingcode
            );
            return;
          }

          // trường hợp ko tìm thấy đơn
          else {
            if (
              waybillAll.slice(0, 2) === "Vn" ||
              waybillAll.slice(0, 2) === "VN" ||
              waybillAll.slice(0, 2) === "vn" ||
              waybillAll.slice(0, 2) === "vN"
            ) {
              let undf = {
                Key: "NETCO",
                Result:
                  "Để hổ trợ xử lý và tra cứu hàng SAMSUNG ." +
                  " " +
                  Gender +
                  " " +
                  "có thể liên hệ trực tiếp cho Team CSKH ở các miền : KV Miền Bắc: 0367380020, KV Miền Trung: 0938302226, KV Miền Nam: 0918889724. Các bạn CSKH sẽ hướng dẫn mình tra cứu ạ.",
              };
              const dataview = [...data];
              let loading = document.getElementById("loading");
              loading.classList.add("fade");

              dataview.push(undf);
              setdataChat(dataview);
              Task_spChatbot_LogMessage_Save(
                idbot,
                id,
                undf.Result?.replace(/(<([^>]+)>)/gi, ""),
                NameBot,
                NameKh,
                3
              );
              //xóa câu hỏi cũ
              const waybillold = await localStorage.getItem("Waybills");
              localStorage.setItem(
                "Waybills",
                waybillold === "null"
                  ? "_ok_" + ladingcode
                  : waybillold + "_ok_ " + ladingcode
              );
              localStorage.setItem("TrackBill", null);
              setBillDetail("");
              setQuestion("");
              setChatKH("");
              return;
            }

            let undf = {
              Key: "NETCO",
              Result:
                "Hiện tại trong hệ thống không có mã vận đơn này." +
                " " +
                Gender +
                " " +
                " vui lòng kiểm tra lại mã vận đơn hoặc liên hệ với tổng đài 19000328 - 024.99999.0328 để kiểm tra lại. Lưu ý mã như VN8533223-431332 là mã đơn hàng nên vui lòng nhập mã do code ở đường link trong tin nhắn của" +
                " " +
                Gender +
                " " +
                " hoặc liên hệ CSKH để lấy mã vận đơn ạ",
              Keys: 0,
            };
            const dataview = [...data];
            let loading = document.getElementById("loading");
            loading.classList.add("fade");
            localStorage.setItem("TrackBill", null);
            dataview.push(undf);
            setdataChat(dataview);
            Task_spChatbot_LogMessage_Save(
              idbot,
              id,
              undf.Result?.replace(/(<([^>]+)>)/gi, ""),
              NameBot,
              NameKh,
              3
            );
            //xóa câu hỏi cũ
            const waybillold = await localStorage.getItem("Waybills");
            localStorage.setItem(
              "Waybills",
              waybillold === "null"
                ? "_ok_" + ladingcode
                : waybillold + "_ok_ " + ladingcode
            );
            setBillDetail("");
            setQuestion("");
            setChatKH("");
            return;
          }
        }

        let phone = CheckPhone(e.Result);
        if (phone) {
          const viewmsg = {
            Key: "NETCO",
            Result:
              "Dạ em đã tiếp nhận yêu cầu của " +
              Gender.toLowerCase() +
              ".Bên em sẽ cho người liên lạc với " +
              Gender.toLowerCase() +
              " trong thời gian sớm nhất .Cám ơn " +
              Gender.toLowerCase() +
              " đã quan tâm với dịch vụ bên em.",
            Keys: 0,
          };

          // lưu câu hỏi gần nhất
          const dataview = [...data];
          let loading = document.getElementById("loading");
          loading.classList.add("fade");
          dataview.push(viewmsg);
          setStatus("Out");
          Task_spChatbot_LogMessage_Save(
            idbot,
            id,
            viewmsg.Result?.replace(/(<([^>]+)>)/gi, ""),
            NameBot,
            NameKh,
            4
          );
          setdataChat(dataview);
          TheEnd(dataview);
          CreateOrder_Clear();

          return;
        }

        if (keysp) return;
        const viewmsg = {
          Key: "NETCO",
          Result:
            entity?.Percet < 50
              ? randomtxt.replaceAll("Anh/Chị", Gender)
              : entity?.ChatbotAnswer.replaceAll("Anh/Chị", Gender).replaceAll(
                  "A/C",
                  Gender
                ),
          Keys: 0,
        };

        // lưu câu hỏi gần nhất
        const dataview = [...data];
        setStatus("Out");
        setStatusBtn("0");
        let loading = document.getElementById("loading");
        loading.classList.add("fade");
        dataview.push(viewmsg);
        Task_spChatbot_LogMessage_Save(
          idbot,
          id,
          viewmsg.Result?.replace(/(<([^>]+)>)/gi, ""),
          NameBot,
          NameKh,
          entity?.SubjectId !== 0 && entity?.SubjectId !== undefined
            ? entity?.SubjectId
            : 10
        );
        setdataChat(dataview);
        TheEnd();
      }
    } catch (error) {}
  };
  const TheEnd = () => {
    setendtimer(
      window.setTimeout(() => {
        let datanew = [...dataChat];

        const enmsg = {
          Key: "NETCO",
          Result:
            "Dạ GTELPOST không nhận được thông tin tiếp theo từ" +
            " " +
            Gender +
            " " +
            ". Trường hợp " +
            " " +
            Gender +
            " " +
            " cần hỗ trợ trong vấn đề chuyển phát của GTELPOST, vui lòng để lại số điện thoại liên hệ hoặc gọi tổng đài 19000328 và làm theo hướng dẫn ạ. GTELPOST xin cảm ơn!",
          Keys: 0,
        };

        datanew.push(enmsg);
        setdataChat(datanew);
        setendtimer(
          window.setTimeout(() => {
            datanew.slice(5);
            setdataChat(datanew);
          }, 20000)
        );
      }, 600000)
    );
  };
  const CreateOrder = async (dataChatAll) => {
    let Cscd = await CheckCustomercode(dataChatAll);

    let checkcode = await CheckCSCode(Cscd);

    if (checkcode === "OK") {
      setCustomerCode(Cscd);
    }

    //lấy đ/c
    let raw = removeAccents(dataChatAll);
    let dc = Getgoods(dataChatAll);

    // let dc2 = await CheckAddress(dataChatAll);
    let dc3 = ClearAdrress(dataChatAll);

    //lấy đơn vị tính
    let text = dataChatAll
      .replace(",", ".")
      .toLowerCase()
      .trim()
      .split(" ")
      .map((element) => element.trim())
      .filter((element) => element !== "");

    let b = text
      .filter(
        (e) =>
          isNaN(e.slice(0, 1)) === false &&
          (isNaN(e.slice(-2)) === true ||
            isNaN(e.slice(-3)) === true ||
            isNaN(e.slice(-4)) === true)
      )
      .find((e) => CheckDetectUnit(e));

    let dvd = "";
    if (b) {
      let trimtxt = b.split("").filter((p) => p !== " ");
      trimtxt.forEach((e) => {
        if (isNaN(e) === true) {
          dvd += e;
        }
      });
    }

    let text2 = dataChatAll
      .replace(",", ".")
      .toLowerCase()
      .trim()
      .split(" ")
      .map((element) => element.trim())
      .filter((element) => element !== "");

    let dvt = text2.find((e) => CheckDetectUnit(e));

    let pst = text2.indexOf(dvt);

    let ab = "";
    if (dvd) {
      let ac = dvd.split("").filter((p) => p !== " ");

      ac.forEach((e) => {
        ab += e;
      });
    }

    let dvc = "";

    if (dvd && (dvt === "" || dvt === undefined)) {
      dvc = dvd;
    } else {
      dvc = dvt;
    }

    if (ab) {
      dvc = ab;
    }

    if (dvt && (b === "" || b === undefined)) {
      dvc = dvt;
    }

    //phân tích lấy khối lượng

    let ar = "";
    if (b) {
      let trimtxt = b.split("").filter((p) => p !== " ");
      trimtxt.forEach((element) => {
        if (isNaN(element) === false || element === ".") {
          ar += element;
        }
      });
    }
    let weightok = text2[pst - 1];

    let weight2 = 0;
    if (weightok !== 0 && weightok !== undefined) {
      weight2 = +weightok;
    }
    let klg = 0;
    if (weightok !== 0) {
      klg = +weight2;
    }
    if (ar && weightok) {
      klg = +ar;
    }

    let checkunit = CheckUnit(dvc)?.Unit;

    // lấy sđt

    let phoneraw = Getphone(raw);
    let phonemini = dataChatAll
      .trim()
      .replace(",", " ")
      .replace(".", " ")
      .split(" ")
      .map((element) => element.trim())
      .filter((element) => element !== "")
      .find(
        (p) =>
          p.slice(0, 4) === "sđt:" ||
          p.slice(0, 4) === "sdt:" ||
          p.slice(0, 6) === "thoại:"
      );
    let phonecombine = "";
    if (phonemini) {
      let phoneslice = phonemini.split("").filter((e) => e !== "");

      phoneslice.forEach((e) => {
        if (isNaN(e) === false) {
          phonecombine += e;
        }
      });
    }
    let phone = phoneraw
      .trim()
      .replace(",", " ")
      .split(" ")
      .map((element) => element.trim())
      .filter((element) => element !== "")
      .find((p) => p.slice(0, 1) === "0");

    let phonechk = "";
    if (dataChatAll) {
      phonechk = CheckPhone(dataChatAll);
    }
    let finalphone = "";

    if (phone && (phonechk === "" || phonechk === undefined)) {
      finalphone += phone;
    }

    if (phonecombine && (phone === "" || phone === undefined)) {
      finalphone += phonecombine;
    } else {
      finalphone += phonechk;
    }

    let pr = {
      CustomerCode: Cscd !== "" && Cscd !== undefined ? Cscd : "",
      Phone: finalphone,
      Weight: klg,
      Address: dc ? dc : dc3,
      Dvc: dvc,
    };

    return pr;
  };
  const CreateOrder_Clear = () => {
    setOrderAddre("");
    setOrderPhone("");
    setOrderWeight("");
    setOrderNote("");
    setCustomerCode("");
    setStatusBtn("");
    setOrderData("");
    setWeightUnit({ value: 1, label: "kilogram" });
  };
  const CreatePrices = (dataChatAll) => {
    let dtarex = getFrom(dataChatAll);

    let checksrc = dtarex[0];

    let text = checksrc.str;
    let frid = checksrc.fid;
    const topoint = dataChatAll
      .replace(",", " ")
      .replace(",", ".")
      .trim()
      .split(" ")
      .map((element) => element.trim())
      .filter((element) => element !== "");

    let lct = 0;
    if (text) {
      lct = CheckLocation(text, listState);
    }

    let tid = "";
    if (lct) {
      tid = lct.value;
    }

    //lấy đơn vị tính
    let text1 = dataChatAll
      .replace(",", ".")
      .toLowerCase()
      .trim()
      .split(" ")
      .map((element) => element.trim())
      .filter((element) => element !== "");

    let b = "";

    if (text1) {
      b = text1
        .filter(
          (e) =>
            isNaN(e.slice(0, 1)) === false &&
            (isNaN(e.slice(-2)) === true ||
              isNaN(e.slice(-3)) === true ||
              isNaN(e.slice(-4)) === true)
        )
        .find((e) => CheckDetectUnit(e));
    }

    let dvd = "";
    if (b) {
      let trimtxt = b.split("").filter((p) => p !== " ");
      trimtxt.forEach((e) => {
        if (isNaN(e) === true) {
          dvd += e;
        }
      });
    }

    let text2 = dataChatAll
      .replace(",", ".")
      .toLowerCase()
      .trim()
      .split(" ")
      .map((element) => element.trim())
      .filter((element) => element !== "");

    let dvt = text2.find((e) => CheckDetectUnit(e));
    let pst = text2.indexOf(dvt);

    let ab = "";
    if (dvd) {
      let ac = dvd.split("").filter((p) => p !== " " && p !== "," && p !== ".");
      ac.forEach((e) => {
        ab += e;
      });
    }

    let dvc = "";

    if (dvd && (dvt === "" || dvt === undefined)) {
      dvc = dvd;
    } else {
      dvc = dvt;
    }

    if (ab && ab !== undefined) {
      dvc = ab;
    }

    if (dvt && (b === "" || b === undefined)) {
      dvc = dvt;
    }

    //phân tích lấy khối lượng

    let ar = "";
    if (b) {
      let trimtxt = b.split("").filter((p) => p !== " ");
      trimtxt.forEach((element) => {
        if (isNaN(element) === false || element === ".") {
          ar += element;
        }
      });
    }

    let weightok = text2[pst - 1];

    let weight2 = 0;
    if (weightok !== 0 && weightok !== undefined) {
      weight2 = +weightok;
    }
    let klg = 0;
    if (weightok !== 0) {
      klg = +weight2;
    }
    if (ar && weightok !== "") {
      klg = +ar;
    }

    let checkunit = CheckUnit(dvc, klg)?.Unit;

    let pr = {
      StartPoint: frid,
      ToPoint: tid,
      Weight: klg,
      DVC: dvc,
      StartLabel: dtarex[0]?.frlb,
      ToLabel: dtarex[0]?.str,
    };
    return pr;
  };
  const CheckTimes = (dataChatAll, e) => {
    let dtarex = getFrom(dataChatAll);
    let checksrc = dtarex[0];

    let text = "",
      frid = 0;

    if (checksrc) {
      text = checksrc.str;
      frid = checksrc.fid;
    }

    let lct = CheckLocation(text, listState);
    let tid = 0;
    if (lct) {
      tid = lct.value;
    }
    let pr = {
      StartPoint: frid,
      ToPoint: tid,
    };
    return pr;
  };
  // đưa vào 1 chuổi và 1 mảng data, sẽ trả về đối tượng tìm kiếm có % đúng nhất
  const CheckResult = (Result, dataprocess) => {
    let Arquestion =
      Result !== undefined &&
      removeAccents(Result)
        ?.replace(",", " ")
        .trim() // bỏ khoảng trắng ở đầu và cuối câu
        // .replace(/[^a-zA-Z\s&]/g, "") // bỏ ký tự đặc biệt
        //.replace(/[#^()/><{}]/g, "")
        .split(" ")
        .map((element) => element.trim()) // bỏ các ký tự rỗng nằm giửa các câu
        .filter((element) => element !== ""); // bỏ các phần tử rỗng

    let datadkm = [];
    if (dataprocess) {
      datadkm = dataprocess.map((item, index) => {
        return {
          ChatbotId: item.ChatbotId,
          ChatbotQuestion: item.ChatbotQuestion,
          ChatbotAnswer: item.ChatbotAnswer,
          SubjectName: item.SubjectName,
          SubjectId: item.SubjectId,
          IsSpecial: item.IsSpecial,
          Percet: 0,
          Number: item.Number,
        };
      });
    }

    //return
    Arquestion &&
      Arquestion.forEach((element) => {
        // lặp qua từng câu trong câu hỏi

        datadkm.forEach((item) => {
          // lặp qua data câu hỏi
          const newitem = item.ChatbotQuestion.split(" ")
            .map((elt) => elt.trim()) // bỏ các ký tự rỗng nằm giửa các câu
            .filter((elt) => elt !== ""); // bỏ các phần tử rỗng; // cắt từng câu trong câu hỏi

          const newitempercent = 100 / parseInt(Arquestion.length); // tính số % trong câu trả lời
          newitem.forEach((itm, index) => {
            if (element === removeAccents(itm)) {
              // nếu từng câu trong câu hỏi, giống với câu trả lời
              // itemok.Percet = itemok.Percet === undefined ? newitempercent : itemok.Percet + newitempercent;
              // resultsSearch.push(itemok);
              let percent = 0;
              if (item.Percet === undefined) {
                percent = newitempercent;
              } else if (item.Percet + newitempercent >= 100) {
                percent = 100;
              } else {
                percent = item.Percet + newitempercent;
              }

              datadkm.find((p) => p.ChatbotId === item.ChatbotId).Percet =
                percent;
            }
          });
        });
      });

    let checkdata = datadkm
      .filter((p) => p.Percet <= 100)
      .sort((a, b) => parseFloat(a.Number) - parseFloat(b.Number))
      .sort((a, b) => parseFloat(b.Percet) - parseFloat(a.Percet));
    let Searchfinal = checkdata.slice(0, 1)[0];

    let ab = true;
    Array.length > 0 &&
      Array.forEach((item) => {
        if (item.idcheck === Searchfinal.SubjectId) {
          ab = false;
        }
      });
    if (Searchfinal.Percet > 50 && ab !== false) {
      Task_spChatbotQuestion_ListBySubjectId(
        Searchfinal.SubjectId,
        Searchfinal.SubjectName
      );
    }
    setChatKH("");
    return Searchfinal;
  };
  const CheckResult2 = (Result, dataprocess) => {
    let dta = [...dataAnswer];

    let Searchfinal = [];

    dataprocess.forEach((itm2) => {
      const ab = CheckResult(Result, itm2.content);
      Searchfinal.push(ab);
    });
    let checkdata = Searchfinal.filter((p) => p.Percet <= 100)
      .sort((a, b) => parseFloat(a.Number) - parseFloat(b.Number))
      .sort((a, b) => parseFloat(b.Percet) - parseFloat(a.Percet));
    let ab = checkdata.slice(0, 1)[0];
    if (ab.Percet < 50) {
      const ab = CheckResult(Result, dta);

      return ab;
    }

    let idpast = parseInt(localStorage.getItem("PastId"));
    if (idpast !== ab.SubjectId) {
      localStorage.setItem("GetGoods", null);
      localStorage.setItem("GetPrice", null);
      localStorage.setItem("GetTime", null);
      localStorage.setItem("TrackBill", null);
      localStorage.setItem("Complain", null);
    }
    setChatKH("");
    return ab;
  };
  const CheckWaybill = (waybill) => {
    if (waybill) {
      let ab = waybill
        ?.toLowerCase()
        .replace(",", " ")
        .replace("/", " ")
        .trim()
        .split(" ")
        .map((element) => element.trim())
        .filter((element) => element !== "");

      let bill = "";
      ab.forEach((element) => {
        // lặp qua từng câu trong câu hỏi
        const CheckWaybillString = element.slice(0, 2); //cắt 2 kí tự đầu
        const CheckWaybillNumber = element.slice(2); //cắt từ vị trí t3
        const CheckWaybillString3 = element.slice(0, 3); //cắt từ vị trí t3
        const CheckWaybillNumber3 = element.slice(3); //cắt từ vi trí t4
        const CheckWaybillString5 = element.slice(0, 5); //cắt từ vị trí t5
        const CheckWaybillNumber5 = element.slice(5); //cắt từ vi trí t5
        const CheckAllNumber = element.slice(0);

        if (
          // kiểm tra 2 kí đầu là chuỗi và các kí tự sau là chuỗi
          ((typeof CheckWaybillString === "string" &&
            isNaN(CheckWaybillNumber) === false &&
            CheckWaybillNumber !== "") ||
            // kiểm tra 3 kí đầu là chuỗi và các kí tự sau là chuỗi
            (typeof CheckWaybillString3 === "string" &&
              isNaN(CheckWaybillNumber3) === false &&
              CheckWaybillNumber3 !== "") ||
            // kiểm tra 5 kí đầu là chuỗi và các kí tự sau là chuỗi
            (typeof CheckWaybillString5 === "string" &&
              isNaN(CheckWaybillNumber5) === false &&
              CheckWaybillNumber5 !== "") ||
            // kiểm tra 2 kí đầu là VN
            CheckWaybillString === "VN" ||
            CheckWaybillString === "vn" ||
            CheckWaybillString === "Vn" ||
            CheckWaybillString === "vN" ||
            // kiểm tra tất cả là số
            (isNaN(CheckAllNumber) === false &&
              CheckAllNumber !== "" &&
              CheckAllNumber.slice(0, 1) !== "e")) &&
          element.length > 7
        ) {
          bill = element;

          return;
        }
      });

      return bill;
    }
  };
  const CheckUnit = (data, klg) => {
    let dta = data?.toLowerCase();
    let ar = {
      Unit: "",
      Text: 0,
    };
    let unit = "";
    let text = 0;
    switch (dta) {
      case "kg":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "ký":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "kí":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "ky":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "ki":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "kilogram":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "klg":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "ký lô":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "kí lô":
        text = +klg * 1000;
        unit = "kilogram";
        setWeightUnit({ value: 1, label: "kilogram" });
        break;
      case "g":
        text = +klg;
        unit = "gram";
        setWeightUnit({ value: 2, label: "gram" });
        break;
      case "gram":
        text = +klg;
        unit = "gram";
        setWeightUnit({ value: 2, label: "gram" });
        break;
      case "gr":
        text = +klg;
        unit = "gram";
        setWeightUnit({ value: 2, label: "gram" });
        break;
      case "g":
        text = +klg;
        unit = "gram";
        setWeightUnit({ value: 2, label: "gram" });
        break;
      case "khối":
        text = +klg * 330 * 1000;
        unit = "cbm(khối)";
        setWeightUnit({ value: 4, label: "cbm(khối)" });
        break;
      case "cbm":
        text = +klg * 330 * 1000;
        unit = "cbm(khối)";
        setWeightUnit({ value: 4, label: "cbm(khối)" });
        break;
      case "cbm(khối)":
        text = +klg * 330 * 1000;
        unit = "cbm(khối)";
        setWeightUnit({ value: 4, label: "cbm(khối)" });
        break;
      case "tấn":
        text = +klg * 1000 * 1000;
        unit = "tấn";
        setWeightUnit({ value: 3, label: "tấn" });
        break;

      default:
        text = +klg;
        unit = "gram";
        setWeightUnit({ value: 2, label: "gram" });
    }

    return (ar = {
      Unit: unit,
      Text: text,
    });
  };
  const CheckDetectUnit = (data) => {
    let dta = removeAccents(data).split(" ");

    let detech = false;

    dta?.forEach((e) => {
      switch (e.replace(/[^a-zA-Z\s&]/g, "")) {
        case "kg":
          detech = true;
          break;
        case "ky":
          detech = true;
          break;
        case "ki":
          detech = true;
          break;
        case "kilogram":
          detech = true;
          break;
        case "klg":
          detech = true;
          break;
        case "ky lô":
          detech = true;
          break;
        case "ki lô":
          detech = true;
          break;
        case "g":
          detech = true;
          break;
        case "gram":
          detech = true;
          break;
        case "gr":
          detech = true;
          break;
        case "g":
          detech = true;
          break;
        case "khoi":
          detech = true;
          break;
        case "cbm":
          detech = true;
          break;
        case "cbm(khoi)":
          detech = true;
          break;
        case "tan":
          detech = true;
          break;

        default:
          detech = false;
          break;
      }
    });
    return detech;
  };
  //kiem tra cu phap don hang
  const CheckLocation = (location, datalist) => {
    let Arquestion =
      location !== undefined &&
      location
        .trim()
        .split(" ")
        .map((element) => element.trim())
        .filter((element) => element !== "");
    let datadkm = datalist.map((item, index) => {
      return {
        value: item.value,
        label: item.label,
      };
    });

    Arquestion &&
      Arquestion.forEach((element) => {
        datadkm.forEach((item) => {
          const newitem = item.label
            .split(" ")
            .map((elt) => elt.trim())
            .filter((elt) => elt !== "");
          const newitempercent = 100 / parseInt(Arquestion.length);
          newitem.forEach((itm, index) => {
            if (element === itm) {
              let percent = 0;
              if (item.Percet === undefined) {
                percent = newitempercent;
              } else if (item.Percet + newitempercent >= 100) {
                percent = 100;
              } else {
                percent = item.Percet + newitempercent;
              }

              datadkm.find((p) => p.value === item.value).Percet = percent;
            }
          });
        });
      });

    let checkdata = datadkm
      .filter((p) => p.Percet <= 100)
      .sort((a, b) => parseFloat(a.Number) - parseFloat(b.Number))
      .sort((a, b) => parseFloat(b.Percet) - parseFloat(a.Percet));
    let Searchfinal = checkdata.slice(0, 1)[0];

    return Searchfinal;
  };
  const CheckAddress = async (location) => {
    const params = {
      Json: JSON.stringify(location),
      func: "Task_spChatbotAddress_Check_v2",
    };
    const list = await mainAction.API_spCallServerSystem(params, dispatch);
    return list;
  };
  const CheckAddress2 = async (location) => {
    let pr = {
      KeySearch: location,
    };

    const params = {
      Json: JSON.stringify(pr),
      func: "Task_spChatbotAddress_Check",
    };
    const list = await mainAction.API_spCallServerSystem(params, dispatch);
    return list;
  };
  const CheckAddressFinal = (Result, data) => {
    let Arquestion =
      Result !== undefined &&
      removeAccents(Result)
        ?.replace(",", " ")
        .trim()
        .split(" ")
        .map((element) => element.trim())
        .filter((element) => element !== "");

    let datadkm = [];
    if (data) {
      datadkm = data.map((item, index) => {
        return {
          Address: item.Address,
          AddressName: item.AddressName,
          CityId: item.CityId,
          DistrictId: item.DistrictId,
          WardId: item.WardId,
          Percet: 0,
          Number: item.Number,
        };
      });
    }

    //return
    Arquestion &&
      Arquestion.forEach((element) => {
        // lặp qua từng câu trong câu hỏi

        datadkm.forEach((item) => {
          // lặp qua data câu hỏi
          const newitem = item.AddressName.split(" ")
            .map((elt) => elt.trim())
            .filter((elt) => elt !== "");

          let arrcheck = [];
          const newitempercent = 100 / parseInt(Arquestion.length);
          newitem.forEach((itm, index) => {
            if (element === removeAccents(itm)) {
              if (arrcheck.includes(element) === false) {
                let percent = 0;
                if (item.Percet === undefined) {
                  percent = newitempercent;
                } else if (item.Percet + newitempercent >= 100) {
                  percent = 100;
                } else {
                  percent = item.Percet + newitempercent;
                }

                datadkm.find((p) => p.Address === item.Address).Percet =
                  percent;
                arrcheck.push(element);
              }
            }
          });
        });
      });

    let checkdata = datadkm
      .filter((p) => p.Percet <= 100)
      .sort((a, b) => parseFloat(a.Number) - parseFloat(b.Number))
      .sort((a, b) => parseFloat(b.Percet) - parseFloat(a.Percet));
    let Searchfinal = checkdata.slice(0, 1)[0];
    setChatKH("");
    return Searchfinal;
  };
  const CheckCustomercode = (code) => {
    if (code !== "" && code !== undefined) {
      let detailcode = code
        .toLowerCase()
        .replace("/", " ")
        .replace(",", " ")
        .trim()
        .split(" ")
        .map((element) => element.trim())
        .filter((element) => element !== "");

      let C_Code = "";
      detailcode.forEach(async (element) => {
        // lặp qua từng câu trong câu hỏi
        const CheckWaybill = element.slice(0, 1);
        const CheckWaybillString = element.slice(0, 2);
        const CheckWaybillNumber = element.slice(2);
        const CheckWaybillString3 = element.slice(0, 3);
        const CheckWaybillNumber3 = element.slice(3);
        const CheckWaybillString5 = element.slice(0, 5);
        const CheckWaybillNumber5 = element.slice(5);

        if (
          ((typeof CheckWaybillString === "string" &&
            isNaN(CheckWaybillNumber) === true &&
            CheckWaybillNumber !== "") ||
            (typeof CheckWaybillString3 === "string" &&
              isNaN(CheckWaybillString3) === true &&
              CheckWaybillNumber3 !== "") ||
            (typeof CheckWaybillString5 === "string" &&
              isNaN(CheckWaybillString5) === true &&
              CheckWaybillNumber5 !== "")) &&
          isNaN(CheckWaybill) === true &&
          CheckWaybill !== "0" &&
          element.length > 7
        ) {
          C_Code = element;
          return C_Code;
        }
      });
      return C_Code;
    }
  };
  const CheckCSCode = async (code) => {
    let checkcscode = "";
    if (code) {
      let ak = {
        CustomerCode: code,
      };
      const params = {
        Json: JSON.stringify(ak),
        func: "CPN_spCustomer_CheckCode",
      };

      const list = await mainAction.API_spCallServerSystem(params, dispatch);

      if (list.Key === "NOTOK" || list.Status === "NOTOK") {
        checkcscode = "NOTOK";
      } else {
        checkcscode = "OK";
      }
      return checkcscode;
    } else {
      return (checkcscode = "NOTOK");
    }
  };
  const CheckInfor = (data) => {
    if (
      (data.includes("từ") && data.includes("đi")) ||
      (data.includes("từ") && data.includes("về")) ||
      (data.includes("đi") && data.includes("về")) ||
      (data.includes("từ") && data.includes("đến")) ||
      (data.includes("from") && data.includes("to"))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const CheckPhone = (code) => {
    if (code) {
      let phonedetail = code
        ?.replace(",", " ")
        .trim()
        .split(" ")
        .map((element) => element.trim())
        .filter((element) => element !== "");

      let phone = "";
      phonedetail.forEach((element) => {
        const CheckAllNumber = element.slice(0);
        const AB = CheckAllNumber.slice(0, 1);

        if (
          isNaN(CheckAllNumber) === false &&
          CheckAllNumber !== "" &&
          AB === "0" &&
          element.length < 12 &&
          element.length > 9
        ) {
          phone = element;
          return;
        }
      });
      return phone;
    }
  };
  const CheckGoods = (text, data) => {
    let Arquestion =
      text !== undefined &&
      text
        .toLowerCase()
        .trim()
        .split(" ")
        .map((element) => element.trim())
        .filter((element) => element !== "");

    let datadkm = data.map((item, index) => {
      return {
        value: item.value,
        label: item.label,
      };
    });

    Arquestion &&
      Arquestion.forEach((element) => {
        datadkm?.forEach((item) => {
          const newitem = item.label
            ?.split(" ")
            .map((elt) => elt.trim())
            .filter((elt) => elt !== "");
          const newitempercent = 100 / parseInt(Arquestion.length);
          newitem?.forEach((itm, index) => {
            if (element === itm) {
              let percent = 0;
              if (item.Percet === undefined) {
                percent = newitempercent;
              } else if (item.Percet + newitempercent >= 100) {
                percent = 100;
              } else {
                percent = item.Percet + newitempercent;
              }

              datadkm.find((p) => p.label === item.label).Percet = percent;
            }
          });
        });
      });

    let checkdata = datadkm
      ?.filter((p) => p.Percet <= 100)
      .sort((a, b) => parseFloat(a.Number) - parseFloat(b.Number))
      .sort((a, b) => parseFloat(b.Percet) - parseFloat(a.Percet));
    let Searchfinal = checkdata?.slice(0, 1)[0];
    return Searchfinal;
  };
  const CheckOrder = (text, data) => {
    let Arquestion = text !== undefined && text.toLowerCase();
    let Searchfinal = {
      Percet: 0,
    };
    if (
      Arquestion.includes("lấy hàng") ||
      Arquestion.includes("xuống lấy hàng") ||
      Arquestion.includes("qua lấy hàng") ||
      Arquestion.includes("tới lấy hàng") ||
      Arquestion.includes("lấy thư") ||
      Arquestion.includes("qua lấy thư") ||
      Arquestion.includes("xuống lấy thư")
    ) {
      Searchfinal = {
        Percet: 100,
      };
    }

    return Searchfinal;
  };
  //List của chủ đề

  //#region List dữ liệu

  const Task_spRandomChatbotMessage_List = async () => {
    let pr = {
      KeySearch: "",
      IdRandom: 0,
    };
    const params = {
      Json: JSON.stringify(pr),
      func: "Task_spRandomChatbotMessage_List",
      API_key: "netcoApikey2025",
    };
    const list = await mainAction.API_spCallServerSystem(params, dispatch);
    setdataRandom(list);
  };
  //List thực thể theo chủ đề
  const Task_spChatbotQuestion_ListBySubjectId = async (id, name) => {
    let data = [...dataAnswer];
    let pr = {
      KeySearch: txtSearch,
      SubjectId: id,
    };
    const params = {
      Json: JSON.stringify(pr),
      func: "Task_spChatbotQuestion_ListBySubjectId",
    };

    const list = await mainAction.API_spCallServerSystem(params, dispatch);

    const ac = {
      idcheck: id,
      topic: name,
      content: list,
      Bigdata: dataAnswer,
    };
    Array.push(ac);
    setdataAnswer(data.filter((p) => p.SubjectId !== id));
  };
  const Task_spChatbotQuestion_List = async () => {
    let pr = {
      KeySearch: txtSearch,
      SubjectId: SubjectId,
    };
    const params = {
      Json: JSON.stringify(pr),
      func: "Task_spChatbotQuestion_ListBySubjectId",
    };

    const list = await mainAction.API_spCallServerSystem(params, dispatch);
    setdataAnswer(list);
  };

  //#endregion

  // xử lí câu hỏi (phân tích để lấy câu hỏi)
  const [Question, setQuestion] = useState();

  //#region  xử lý sự kiện click,enter
  const handleSearch = (event, text) => {
    //nối chuỗi câu hỏi

    let dataview = [...dataChat];
    setchatbottimer(window.clearTimeout(chatbottimer));
    sethellotimer(window.clearTimeout(hellotimer));
    setendtimer(window.clearTimeout(endtimer));

    if (event.key === "Enter") {
      setStatus("NOTOK");
      $("#pickupformorder").css("display", "none");
      CreateOrder_Clear();
      localStorage.setItem("IdRecieve", null);
      let ab = document.getElementById("loading");
      ab.classList.remove("fade");
      const getChatKHAll = ChatKH === undefined ? text : ChatKH + " " + text;
      setChatKH(getChatKHAll);
      setChatvalue("");

      //kiểm tra câu hỏi và đẩy lên khung chat

      if (text.trim()) {
        const viewmsg = {
          Key: "User",
          Result: text,
          Keys: Math.floor(Math.random() * 100 + 1),
        };

        dataview.push(viewmsg);
        setdataChat(dataview);

        Task_spChatbot_LogMessage_Save(
          id,
          idbot,
          viewmsg.Result?.replace(/(<([^>]+)>)/gi, ""),
          NameKh,
          NameBot,
          22
        );
      }

      if (dataChat && dataChat.length < 2) {
        sethellotimer(
          window.setTimeout(() => {
            const hel = {
              Key: "NETCO",
              Result:
                I18n.t("Chat.Hello1") +
                "  " +
                Gender.toLowerCase() +
                " " +
                I18n.t("Chat.Hello2") +
                ChangeCS.label +
                " " +
                I18n.t("Chat.Hello3") +
                " " +
                Gender +
                I18n.t("Chat.Hello4"),
              Keys: 0,
            };

            dataview.push(hel);
            setdataChat(dataview);
          }, 0)
        );
      }

      //đặt thời gian để lấy chuỗi câu hỏi để phân tích

      setchatbottimer(
        window.setTimeout(() => {
          let cleantext = ReplaceData(getChatKHAll);

          if (cleantext.trim()) {
            const viewmsg = {
              Key: "User",
              Result: cleantext,
              Keys: 0,
            };

            setQuestion(viewmsg);
            ActionChat(viewmsg, dataview);
          }
        }, 3000)
      );
    }
  };
  const clickSearch = (text) => {
    //nối chuỗi câu hỏi

    let dataview = [...dataChat];
    setchatbottimer(window.clearTimeout(chatbottimer));

    let ab = document.getElementById("loading");
    ab.classList.remove("fade");
    const getChatKHAll = ChatKH === undefined ? text : ChatKH + " " + text;
    setChatKH(getChatKHAll);
    setChatvalue("");
    let bd = document.getElementById("ad");
    bd.focus();

    //kiểm tra câu hỏi và đẩy lên khung chat
    if (text.trim()) {
      const viewmsg = {
        Key: "User",
        Result: text,
        Keys: Math.floor(Math.random() * 100 + 1),
      };

      dataview.push(viewmsg);
      setdataChat(dataview);
      Task_spChatbot_LogMessage_Save(
        id,
        idbot,
        viewmsg.Result.replace(/(<([^>]+)>)/gi, ""),
        NameKh,
        NameBot,
        ""
      );
    }

    //đặt thời gian để lấy chuỗi câu hỏi để phân tích
    setchatbottimer(
      window.setTimeout(() => {
        let cleantext = ReplaceData(getChatKHAll);

        if (cleantext.trim()) {
          const viewmsg = {
            Key: "User",
            Result: cleantext,
            Keys: 0,
          };

          setQuestion(viewmsg);
          ActionChat(viewmsg, dataview);
        }
      }, 3000)
    );
    // }
  };
  //#endregion
  const ReplaceData = (text) => {
    let cleantext = text
      ?.toLowerCase()
      .replaceAll("cho mình hỏi", " ")
      .replaceAll("cho hỏi", " ")
      .replaceAll("cho anh hỏi", " ")
      .replaceAll("cho chị hỏi", " ")
      .replaceAll("cho em hỏi", " ")
      .replaceAll("cho tôi hỏi", " ")
      .replaceAll("được không", " ")
      .replaceAll("vậy ạ", " ")
      .replaceAll("với ạ", " ")
      .replaceAll("với nhé", " ")
      .replaceAll("được chứ", " ")
      .replaceAll("tôi", " ")
      // .replaceAll("anh", " ")
      .replaceAll("chị", " ")
      .replaceAll("em", " ")
      .replace(/[`~!@#$%^&*()_|+\=?;:'"<>\{\}\[\]\\\/]/gi, "")
      .replace(/['"]+/g, "");
    return cleantext;
  };
  const ClearAdrress = (text) => {
    let point = removeAccents(text)?.indexOf("PHUONG");
    let point2 = removeAccents(text)?.indexOf("XA");
    let point3 = removeAccents(text)?.indexOf("TRAN");

    let textpr = "";
    if (point !== -1) {
      textpr = text.slice(point);
    } else if (point2 !== -1 && point === -1) {
      textpr = text.slice(point2);
    } else if (point3 !== -1 && point === -1) {
      textpr = text.slice(point3);
    } else {
      textpr = text;
    }

    let cleantext = textpr
      ?.toUpperCase()
      .replaceAll("cho mình hỏi", " ")
      .replaceAll("LAY HANG", " ")
      .replaceAll("LẤY HÀNG", " ")
      .replaceAll("huyện", " ")
      .replaceAll("thành phố", " ")
      .replaceAll("THÀNH PHỐ", " ")
      .replaceAll("thị trấn", " ")
      .replaceAll("thị xã", " ")
      .replaceAll("khóm", " ")
      .replaceAll("ngõ", " ")
      .replaceAll("hẻm", " ")
      .replaceAll("tỉnh", " ")
      .replaceAll("lấy hàng", " ")
      .replaceAll("lấy thư", " ")
      .replaceAll("tôi", " ")
      .replaceAll("chị", " ")
      .replaceAll("em", " ")
      .replaceAll("muốn", " ")
      .replaceAll("hi", " ")
      .replaceAll("chào", " ")
      .replaceAll("nhé", " ")
      .replaceAll("kg", " ")
      .replaceAll("địa chỉ", " ")
      .replaceAll("kilogram", " ")
      .replaceAll("gram", " ")
      .replaceAll("Note", " ")
      .replaceAll(",", " ")
      .replaceAll(":", " ")
      .replaceAll(".", " ")
      .replaceAll("!", " ")
      .replaceAll("?", " ")
      .replaceAll("-", " ")
      .replaceAll("_", " ");
    let textchange = removeAccents(cleantext);

    return textchange;
  };
  //loại bỏ kí tự đặc biệt
  const removeAccents = (str) => {
    if (str) {
      const data = str
        ?.toUpperCase()
        .replace(/ỏ/g, "o") // bỏ hoa thường
        .normalize("NFD") // bỏ chữ có dâu
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/đ/g, "d")
        .replace(/Đ/g, "D")
        .replace("-", " ");
      // .replace("/", " ");
      return data;
    }
  };
  //Chức năng lưu hộp thoại chat
  const Task_spChatbot_LogMessage_Save = async (
    idsend,
    idrecieve,
    dta,
    namesend,
    namerecieve,
    ChatbotId
  ) => {
    let date = new Date();
    let groupid = "Group" + "_" + id + "_" + idbot;
    let pr = {
      Id_ChatBot_log: 0,
      Id_Recieve: idrecieve,
      Name_Recieve: namerecieve,
      Id_Send: idsend,
      Name_Send: namesend,
      Message: dta,
      Status_Read: "false",
      Createtime: date,
      Email_Reacieve: EmailKh,
      Phone_Recieve: PhoneKh,
      Group_Id: groupid,
      ChatbotId: ChatbotId,
      Type: "Web",
    };

    const params = {
      Json: JSON.stringify(pr),
      func: "Task_spChatbot_LogMessage_Save",
    };

    const list = await mainAction.API_spCallServerSystem(params, dispatch);
  };
  //render phần tử trong datachat
  const RenderMessage = () => {
    return dataChat.map((item, index) => {
      if (item.Key === "User") {
        return (
          <div className="chat message-user" key={index}>
            <img
              src="https://play-lh.googleusercontent.com/CzcsdNmIxlMZ3he9H_g6olCRMb30xeFXiYG6UTqgiom-Hah4IMIjUCrUFFjvjR82-WI"
              alt="Customer"
              href="#"
              className="avatar-chat"
            />
            <p className="msg">{item.Result}</p>
          </div>
        );
      }

      // if (item.Key === "TrackingPrice") {
      //   return (
      //     <div  className=" message-bot" key={index}>
      //       <button type="button" className=" btn btn-success" onClick={PriceCheck,PriceWeight)}>
      //          Xác nhận
      //       </button>
      //     </div>
      //   );
      // }
      if (item.Key !== undefined) {
        return (
          <div className="chat message-bot" key={index}>
            <img src={ChangeCS.src} href="#" className="avatar-chat" />
            <p className="msg">
              {ChangeCS.label} : <br />
              <ReactHtml
                html={item.Result}
                key={"htmlbot" + index}
                componentMap={{ ChatBot }}
              />
            </p>
          </div>
        );
      }
    });
  };

  return (
    // LoadingStart !== "OK" ?(<LoadingPageWeb/>):
    <ChatLayout>
      <div className="content">
        <div className="card  m-3">
          <div className="card-body p-0 boxcolor">
            <div
              className="message-box chatbox"
              style={{ height: "calc(100vh -  230px)" }}
            >
              <div className="row">
                <RenderMessage />

                {/* {Status === "OK" ? ( */}
                <div
                  className="card-body"
                  id="pickupformorder"
                  style={{ display: Status === "OK" ? "block" : "none" }}
                >
                  <div className="chat message-bot fixed">
                    <div className="form msg">
                      <div className="form-group">
                        <label>
                          {Gender} vui lòng kiểm tra và nhập các thông tin vào ô
                          bên dưới để thực hiện báo lấy thư hàng nhé!
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="special-label">
                          Mã KH hoặc Số điện thoại
                          <span className="required">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="code"
                          type="text"
                          ref={CustomerCodeRef}
                          value={CustomerCode}
                          {...bindCustomerCode}
                          maxLength="50"
                          placeholder="Nhập mã kh hoặc sđt"
                          onChange={(e) => setCustomerCode(e.target.value)}
                          onKeyDown={onChangeWarning}
                          required
                        />
                        {CustomerCodeStatus === "NOTOK" ? (
                          <label
                            className="special-label"
                            style={{ color: "red", fontSize: "10px" }}
                          >
                            Mã khách hàng chưa được đăng kí, {Gender} vui lòng
                            sử dụng số điện thoại .
                          </label>
                        ) : (
                          ""
                        )}
                        {CustomerStatus === "NOTOK" ? (
                          <label
                            className="special-label"
                            style={{ color: "red", fontSize: "10px" }}
                          >
                            {" "}
                            {Gender} vui lòng cung cấp mã KH hoặc số điện thoại
                            .
                          </label>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <label className="special-label">
                          {I18n.t("Chat.Address")}{" "}
                          <span className="required">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="address"
                          type="text"
                          ref={OrderAddressRef}
                          value={OrderAddre}
                          {...bindOrderAddre}
                          onChange={(e) => setOrderAddre(e.target.value)}
                          onKeyDown={onChangeWarning2}
                          placeholder="Nhập địa chỉ"
                          required
                        />

                        {OrderAddreStatus === "NOTOK" ? (
                          <label
                            className="special-label"
                            style={{ color: "red", fontSize: "15px" }}
                          >
                            {/* {Error} */}
                            {Error}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <label className="special-label">
                          Trọng lượng<span className="required">* </span>{" "}
                        </label>
                        <div className="row">
                          <div className="col-sm-10 col-md-6">
                            <input
                              className="form-control mt-2"
                              style={{ width: "130%", height: "38px" }}
                              id="weight"
                              type="number"
                              ref={OrderWeighttRef}
                              value={OrderWeight}
                              {...bindOrderWeight}
                              maxLength="10"
                              onChange={(e) => setOrderWeight(e.target.value)}
                              onKeyDown={onChangeWarning3}
                              placeholder="Nhập trọng lượng"
                              required
                            />
                            {OrderWeightStatus === "NOTOK" ? (
                              <label
                                className="special-label"
                                style={{ color: "red", fontSize: "10px" }}
                              >
                                {Gender} vui lòng nhập trọng lượng đơn hàng .
                              </label>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="col-sm-10 col-md-6"
                            style={{ marginBottom: "10px" }}
                          >
                            <SelectWeight
                              onSelected={(e) => onSelectWeight(e)}
                              Items={WeightUnit}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="special-label">Ghi chú </label>
                        <input
                          className="form-control"
                          id="note"
                          type="text"
                          ref={OrderNoteRef}
                          value={OrderNote}
                          {...bindOrderNote}
                          placeholder="Nhập ghi chú"
                        />
                      </div>

                      <div className="form-group text-right mb20">
                        {ConfirmStatus === "OK" ? (
                          <button
                            type="button"
                            className="btn btn-success pd-30"
                            disabled={disabled}
                            onClick={(e) => AllSearch()}
                          >
                            Xác nhận thông tin{" "}
                          </button>
                        ) : (
                          <div className="form-group">
                            <p>Yêu cầu đã gửi đi...</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* ) : (
                  ""
                )} */}
              </div>

              <div
                className="col-sm-12 col-md-12 fade "
                style={{ marginBottom: "30px" }}
                id="loading"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 ">
                    <p style={{ marginTop: "10px" }}>Đang trả lời</p>
                  </div>

                  <div className="col-sm-12 col-md-4 ">
                    <img
                      id="loadingimg"
                      className="loading"
                      src="https://brics-econ.arphahub.com/i/simple_loading.gif"
                    />
                    <div id={"el"} ref={back}></div>
                  </div>
                </div>
              </div>
              {/* <button
                type="button"
                className="btn btn-success "
                onClick={() => setStatus("OK")}
              >
                Tạo đơn ngay
              </button> */}
            </div>
          </div>
          <div
            className="card-footer bg-transparent border-0"
            style={{ width: "100%", bottom: "0px" }}
          >
            <div
              className="form-group chat-input-group "
              style={{ marginBottom: "0px" }}
            >
              <div className="input-group">
                <input
                  id="ad"
                  type="text"
                  value={Chatvalue}
                  placeholder={I18n.t("Chat.InputMessage")}
                  style={{ color: "green" }}
                  disabled
                  onChange={(e) => setChatvalue(e.target.value)}
                  onKeyUp={(e) => handleSearch(e, e.target.value)}
                  //   onClick={window.scrollTo(0, 0)}
                  //   disabled
                  className="form-control input-send"
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => clickSearch(Chatvalue)}
                  >
                    {I18n.t("Chat.Send")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="zalo-follow-only-button"
        data-oaid="4595161656116022900"
      ></div>
    </ChatLayout>
  );
};

export const ChatBot = React.memo(ChatBotComp);
