import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ReactHtml from "raw-html-react";
import MetaTags from "react-meta-tags";

import { FILE_URL, CUSTOMER_CREATE_LADING_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { ScrollTop } from "../../Utils";
import I18n from "../../Language";
import MainLayout from "../../Layout/MainLayout";

export const AboutUs = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [Info, setInfo] = useState({});
  const [List, setList] = useState([]);

  useEffect(() => {
    let type = "";
    if (location.pathname === "/tro-giup") type = "Helper";
    else if (location.pathname === "/chinh-sach") type = "Policy";
    else if (location.pathname === "/quy-dinh-chung") type = "Rules";
    else type = "About";

    const pr = new URLSearchParams(location.search);
    onGetData(type, pr.get("title"));
  }, [location]);

  const onGetData = async (type, title) => {
    let keyLang = localStorage.getItem("keyLang");
    const params = {
      Json: '{"Type":"' + type + '","Enabled":1,"Lang":"' + keyLang + '"}',
      func: "WS_spGetInformation",
    };

    try {
      const list = await mainAction.CallApiWs(params, dispatch);
      setInfo(list.find((p) => p.Url === title));
      setList(list.filter((p) => p.Url !== title));
      ScrollTop();
    } catch (err) {}
  };

  const SEO = (
    <MetaTags>
      <title>{Info?.Title}</title>
      <meta name="description" content={Info?.ShortDesc} />
      <meta property="og:image" content={Info?.ImgThumb} />
      <meta property="og:title" content={Info?.Title} />
      <meta property="og:description" content={Info?.ShortDesc} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content={Info?.Title} />
    </MetaTags>
  );

  return (
    <MainLayout>
      <section className="content">
        {SEO}
        <div className="container">
          <div className="sidemap">
            <span>
              <Link to="/">{I18n.t("TopMenu.Home")}</Link>
            </span>
            <span>
              <i className="fa fa-angle-right"></i>
            </span>
            <span>{Info?.Title}</span>
          </div>
          <div className="row">
            <div className="col-md-8 text-justify">
              <h1 className="h3 mb10">{Info?.Title}</h1>
              <ReactHtml
                html={Info?.LongDesc?.replaceAll(
                  'src="/Image/',
                  'src="' + FILE_URL + "image/"
                )
                  .replaceAll('src="/image/', 'src="' + FILE_URL + "image/")
                  .replaceAll(
                    '<iframe src="/Image/',
                    '<iframe src="https://docs.google.com/gview?url=' +
                      FILE_URL +
                      "image/"
                  )}
                componentMap={{ AboutUs }}
              />
            </div>
            <div className="col-md-4 sidebar-right">
              {/* <h5 className="mt20 mb20 bold">{I18n.t("Sidebar.RelatedPosts")}</h5>
                        {
                            List.map((item, index) => {
                                return (
                                    <Link to={location.pathname + "?title=" + item.Url} title={item.Title} key={"another" + index}>
                                        <div className="box-li">
                                            <span>
                                                <img className="img-thumb" src={FILE_URL + item.ImgThumb} alt={item.Title} />
                                                <img className="img-hover" src={FILE_URL + item.ImgFull} alt={item.Title} />
                                            </span>
                                            <span className="mt5">{item.Title}</span>
                                        </div>
                                    </Link>
                                )
                            })
                        } */}
              <div className="row">
                <div className="col-md-12 box-share mb10">
                  <div className="text-lg-bolder mb10 mt20">
                    {I18n.t("News.ShareToSocial")}
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=" +
                      window.location.href
                    }
                    onclick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                  >
                    <img
                      src="/assets/img/fb-square.png"
                      alt="Share on Facebook"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://plus.google.com/share?url=" +
                      window.location.href
                    }
                    onclick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                  >
                    <img
                      src="/assets/img/gg-square.png"
                      alt="Share on Google+"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://twitter.com/intent/tweet?original_referer=" +
                      window.location.href +
                      "%2F&amp;source=tweetbutton&amp;text=" +
                      Info.Name +
                      "&amp;url=" +
                      window.location.href +
                      "%2F&amp;via="
                    }
                    onclick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                  >
                    <img
                      src="/assets/img/tw-square.png"
                      alt="Share on Twitter"
                    />
                  </a>
                </div>
                <div className="col-md-12">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={CUSTOMER_CREATE_LADING_URL}
                    className="btn btn-success w-100 white"
                  >
                    {I18n.t("Banner.CreateLading")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
