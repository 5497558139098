import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ReactHtml from "raw-html-react";

import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { Alertsuccess, Alertwarning, ScrollTop } from "../../Utils";
import I18n from "../../Language";

export const CustomerSurvey = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [Criterial, setCriterial] = useState([]);
  const [Company, setCompany] = useState("");
  const [Address, setAddress] = useState("");
  const [Tel, setTel] = useState("");
  const [FullName, setFullName] = useState("");
  const [Position, setPosition] = useState("");
  const [CustomerIdEncrypt, setCustomerIdEncrypt] = useState("");

  useEffect(() => {
    debugger;
    const pr = new URLSearchParams(location.search);
    if (pr.get("data") !== "") {
      setCustomerIdEncrypt(pr.get("data"));
      onGetData(pr.get("data"));
    }
  }, []);

  const onGetData = async (id) => {
    debugger;
    const params = {
      ApiKey: APIKey,
      Json: '{"CustomerIdEncrypt":"' + id + '"}',
      func: "WS_spRateCriterial_Get",
    };
    try {
      const list = await mainAction.API_spCallServerSystem(params, dispatch);
      debugger;
      setCriterial(list);
      ScrollTop();
    } catch (err) {}
  };

  const onChangeNotes = (Id, Notes) => {
    let obj = [...Criterial];
    let b = obj.map((item, index) => {
      if (item.CriteriaId === Id) item.Notes = Notes;
      return item;
    });
    setCriterial(b);
  };

  const onChangeChild = (Id, Point) => {
    let obj = [...Criterial];
    let b = obj.map((item, index) => {
      if (item.CriteriaId === Id) item.Point = Point;
      return item;
    });
    setCriterial(b);
  };

  const onSendSurvey = async () => {
    const obj = {
      Company: Company,
      Address: Address,
      Tel: Tel,
      FullName: FullName,
      Position: Position,
      RateDetail: Criterial,
      CustomerIdEncrypt: CustomerIdEncrypt,
    };
    if (Company.length === 0) {
      Alertwarning("Vui lòng nhập tên công ty !");
      return;
    } else if (Address.length === 0) {
      Alertwarning("Vui lòng nhập Địa chỉ !");
      return;
    } else if (Tel.length === 0) {
      Alertwarning("Vui lòng nhập số điện thoại !");
      return;
    } else if (FullName.length === 0) {
      Alertwarning("Vui lòng nhập họ tên !");
      return;
    } else if (Position.length === 0) {
      Alertwarning("Vui lòng nhập tên chức vụ !");
      return;
    }
    const params = {
      ApiKey: APIKey,
      Json: JSON.stringify(obj),
      func: "CRM_spCustomerRate_Save",
    };
    try {
      const list = await mainAction.API_spCallServerSystem(params, dispatch);
      debugger;
      Alertsuccess(list.ReturnMess);
      ScrollTop();
      setCriterial([]);
    } catch (err) {}
  };

  return (
    <section className="content services">
      {CustomerIdEncrypt !== "" && (
        <div
          className="container"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "30px",
            marginTop: "50px",
            padding: "50px 70px",
          }}
        >
          <div className="row">
            {Criterial.length > 0 && (
              <div className="col-md-12">
                <div className="text-justify font-size-15">
                  <p>
                    TRUNG TÂM DỊCH VỤ VIỄN THÔNG - CHI NHÁNH TỔNG CÔNG TY CÔNG
                    NGHỆ - VIỄN THÔNG TOÀN CẦU (GTELPOST) xin trân trọng gửi lời
                    cảm ơn Quý khách hàng trong thời gian qua đã tin tưởng sử
                    dụng dịch vụ chuyển phát của GTELPOST.
                  </p>
                  <p>
                    Để nâng cao chất lượng dịch vụ, xin Quý Khách hàng vui lòng
                    dành một chút thời gian để tham gia cuộc khảo sát ngắn này.
                    Phản hồi của quý khách hàng có giá trị rất lớn đối với chúng
                    tôi, là cơ sở giúp chúng tôi tiếp tục cải tiến chất lượng
                    dịch vụ ngày một tốt hơn.
                  </p>
                  <p>
                    Chúng tôi xin trân trọng cảm ơn sự hợp tác của Quý khách!
                  </p>
                  <hr
                    style={{
                      width: "300px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderColor: "#777",
                    }}
                  />
                  <p>
                    Center of Telecommunication services - Branch of Global
                    Technology – Telecommunication corporation to thank you for
                    trusting in using GTELPOST's delivery service this whole
                    time.
                  </p>
                  <p>
                    GTELPOST truly appreciates for the sincere comments in the
                    previous survey. Therefore, GTELPOST certified seriously
                    this contributions and going to proceed with more plans
                    improve our service quality.
                  </p>
                  <p>We are grateful for Your cooperation!</p>
                </div>
                <h5 className="mt30 mb20">
                  <b>THÔNG TIN QUÝ KHÁCH HÀNG / INFORMATION OF CLIENTS</b>
                </h5>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      value={Company}
                      onChange={(e) => setCompany(e.target.value)}
                      className="form-control"
                      placeholder="Tên công ty/ Company name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={Address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="form-control"
                      placeholder="Địa chỉ/ Address"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={Tel}
                      onChange={(e) => setTel(e.target.value)}
                      className="form-control"
                      placeholder="Số điện thoại/ Tel"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={FullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="form-control"
                      placeholder="Họ và tên/ Represented by"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={Position}
                      onChange={(e) => setPosition(e.target.value)}
                      className="form-control"
                      placeholder="Chức vụ/ Position"
                    />
                  </div>
                  <div className="row font-size-15">
                    <div className="col-md-12 mb10 mt30">
                      <div className="row text-center">
                        <div className="col-md-7"></div>
                        <div className="col-md-1">
                          <img
                            width="25"
                            style={{ marginBottom: "5px" }}
                            src="/assets/img/survey/1.png"
                          />
                          <br />
                          {I18n.t("Survey.VeryBad")}
                        </div>
                        <div className="col-md-1">
                          <img
                            width="25"
                            style={{ marginBottom: "5px" }}
                            src="/assets/img/survey/2.png"
                          />
                          <br />
                          {I18n.t("Survey.Bad")}
                        </div>
                        <div className="col-md-1">
                          <img
                            width="25"
                            style={{ marginBottom: "5px" }}
                            src="/assets/img/survey/3.png"
                          />
                          <br />
                          {I18n.t("Survey.Normal")}
                        </div>
                        <div className="col-md-1">
                          <img
                            width="25"
                            style={{ marginBottom: "5px" }}
                            src="/assets/img/survey/4.png"
                          />
                          <br />
                          {I18n.t("Survey.Good")}
                        </div>
                        <div className="col-md-1">
                          <img
                            width="25"
                            style={{ marginBottom: "5px" }}
                            src="/assets/img/survey/5.png"
                          />
                          <br />
                          {I18n.t("Survey.VeryGood")}
                        </div>
                      </div>
                    </div>
                    {Criterial.filter((p) => p.ParentId === 0).map(
                      (item, index) => {
                        let child = Criterial.filter(
                          (p) => p.ParentId === item.CriteriaId
                        );
                        return (
                          <>
                            {item.Type === 0 && (
                              <div
                                className="col-md-12"
                                key={"criterial" + index}
                              >
                                <b>{item.Title}</b>
                              </div>
                            )}
                            {item.Type === 1 && (
                              <>
                                <div
                                  className="col-md-12 mb10"
                                  key={"criterial" + index}
                                >
                                  <b>{item.Title}</b>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      value={item.Notes}
                                      onChange={(e) =>
                                        onChangeNotes(
                                          item.CriteriaId,
                                          e.target.value
                                        )
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            {child.length > 0 && (
                              <div className="col-md-12">
                                <div className="survey-detail text-center">
                                  {child.map((it, id) => {
                                    return (
                                      <div
                                        className={
                                          "row mt20 survey-item-" + (id + 1)
                                        }
                                      >
                                        <div
                                          className="col-md-7"
                                          key={"child" + id}
                                        >
                                          {it.Title}
                                        </div>
                                        <div className="col-md-1">
                                          <input
                                            onClick={(e) => {
                                              onChangeChild(it.CriteriaId, 1);
                                            }}
                                            name={"child" + it.CriteriaId}
                                            type="radio"
                                          />
                                        </div>
                                        <div className="col-md-1">
                                          <input
                                            onClick={(e) => {
                                              onChangeChild(it.CriteriaId, 2);
                                            }}
                                            name={"child" + it.CriteriaId}
                                            type="radio"
                                          />
                                        </div>
                                        <div className="col-md-1">
                                          <input
                                            onClick={(e) => {
                                              onChangeChild(it.CriteriaId, 3);
                                            }}
                                            name={"child" + it.CriteriaId}
                                            type="radio"
                                          />
                                        </div>
                                        <div className="col-md-1">
                                          <input
                                            onClick={(e) => {
                                              onChangeChild(it.CriteriaId, 4);
                                            }}
                                            name={"child" + it.CriteriaId}
                                            type="radio"
                                          />
                                        </div>
                                        <div className="col-md-1">
                                          <input
                                            onClick={(e) => {
                                              onChangeChild(it.CriteriaId, 5);
                                            }}
                                            name={"child" + it.CriteriaId}
                                            type="radio"
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      }
                    )}
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      style={{ width: "100px" }}
                      onClick={(e) => onSendSurvey()}
                      className="btn btn-success btn-sm"
                    >
                      Gửi
                    </button>
                  </div>
                </form>
              </div>
            )}
            {Criterial.length === 0 && (
              <>
                <div className="col-md-7 text-center h4">
                  <p>
                    GTELPOST xin chân thành cảm ơn Quý khách hàng đã dành thời
                    gian đánh giá trải nghiệm dịch vụ!
                  </p>
                  <p>
                    Chúng tôi hân hạnh tiếp thu ý kiến đóng góp từ Quý khách
                    hàng và rất mong được hợp tác với quý khách về sau.
                  </p>
                  <p>Trân trọng!</p>
                </div>
                <div className="col-md-5">
                  <img src="/assets/img/survey/survey.png" />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
};
