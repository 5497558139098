import { Route, Switch, BrowserRouter } from "react-router-dom";

import {
  BlockContact,
  Chat,
  Footer,
  Header,
  Nav,
  Popup,
} from "../Component/Template";
import {
  Home,
  Contacts,
  Carreers,
  AboutUs,
  News,
  Partners,
  Services,
  //Login, Register,
  Branch,
  ServiceDetail,
  Lading,
  NewsDetail,
  CarreersDetail,
  Complain,
  PriceTracking,
  ThankPage
  
} from "../Component/Main";
import{Confirm} from '../Confirm'
import { CustomerSurvey, AutoSend } from "../Component/Pluggin";
import { CarreersApply } from "../Component/Main/CarreersApply";
import {ChatBot,ChatBotMobile,LoadingPage} from '../Common'


export const Routers = () => {
  let currentUrl = window.location.href;
  // if (currentUrl.indexOf('http://') !== -1 && currentUrl.indexOf('localhost:') === -1) window.location.href = currentUrl.replace('http://', 'https://');
  
  return (
    <BrowserRouter>
      {/* {currentUrl.indexOf("/chatbot") !== -1 ? (
       ''
      ) : ( 
        <>
         
        </>
      )}  */}
        {/* <Route path="/" component={Header} />
          <Route path="/" component={Nav} /> */}
      <Switch>
        <Route exact path="/lien-he" component={Contacts} />
        <Route exact path="/khieu-nai" component={Complain} />
        <Route exact path="/tuyen-dung" component={Carreers} />
        <Route exact path="/thong-tin-tuyen-dung" component={CarreersDetail} />
        <Route exact path="/ung-tuyen" component={CarreersApply} />
        <Route exact path="/gioi-thieu" component={AboutUs} />
        <Route exact path="/thong-tin-cong-ty" component={AboutUs} />
        <Route exact path="/chinh-sach" component={AboutUs} />
        <Route exact path="/tro-giup" component={AboutUs} />
        <Route exact path="/quy-dinh-chung" component={AboutUs} />
        <Route exact path="/tin-tuc" component={News} />
        <Route exact path="/khuyen-mai" component={News} />
        <Route exact path="/hoat-dong" component={News} />
        <Route exact path="/huong-dan" component={News} />
        <Route exact path="/chi-tiet-khuyen-mai" component={NewsDetail} />
        <Route exact path="/chi-tiet-hoat-dong" component={NewsDetail} />
        <Route exact path="/chi-tiet-huong-dan" component={NewsDetail} />
        <Route exact path="/doi-tac" component={Partners} />
        <Route exact path="/da-dang-dich-vu" component={Services} />
        <Route exact path="/dich-vu" component={ServiceDetail} />
        <Route exact path="/loading" component={LoadingPage} />
        {/*  <Route exact path="/dang-nhap" component={Login} />
      <Route exact path="/dang-ky" component={Register} /> */}
        <Route exact path="/diem-gui-hang" component={Branch} />
        <Route exact path="/tra-cuu-don-hang" component={Lading} />
        <Route exact path="/tra-van-don.html" component={Lading} />
        <Route exact path="/uoc-tinh-cuoc-phi" component={PriceTracking} />
        <Route exact path="/khao-sat-khach-hang" component={CustomerSurvey} />
        <Route exact path="/xac-nhan" component={Confirm} />
        {/* <Route exact path="/chat" component={Chat} /> */}
        <Route exact path="/chatbot" component={ChatBot} />
        <Route exact path="/mobile" component={ChatBotMobile} />
        <Route exact path="/thankyou" component={ThankPage} />
        {/* <Route exact path="/autosend" component={AutoSend} /> */}
        {/* <Route exact path="/ten-dich-vu" component={Service} /> */}
        <Route exact path="/" component={Home} />
      </Switch>

      {/* {currentUrl.indexOf("/chatbot") !== -1 ? (
       ''
      ) : ( 
        <> */}
          {/* <Route path="/" component={Footer} />
          <Route path="/" component={Popup} /> */}
          {/* <Route path="/" component={BlockContact} /> */}
          {/* <Route path="/" component={Contacts} /> */}
          {/* <Route path="/" component={Chat} /> */}
        {/* </>
     )}  */}

      {/* <Route path="/" component={ChatBot} />  */}
    </BrowserRouter>
  );
};
