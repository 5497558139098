import { put, takeLatest, takeEvery, take, cancel, delay } from 'redux-saga/effects';
import { mainTypes } from "../Actions";

import { API_WS, APIKey, API_ERP, APIKeyErp, API_CHAT } from "../../Services";
import { apiWS, apiErp, apiChat } from "../../Services/Api";
import { EN, VN, LANE } from '../../Enum';
import { getData } from '../../Utils/Storage';
import I18n from '../../Language'
import { EncodeString } from '../../Utils';

export function* LOADING(action) {
    try {
        delay(500);
        const IsLoading = action && action.params.IsLoading;
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: IsLoading });
    }
    catch (e) {
    }
}

export function* CallApiWs(action) {
    try {
        //show loading
        if (action.params.IsLoading === true)
            yield put({ type: mainTypes.LOADING_SUCCESS, payload: true });
        //params received
        const params = action && action.params
        params.ApiKey = APIKey;
        let FuncApi = "API_spCallServer";
        /// catch api die
        yield delay(300);
        //Check select data redis
        switch (params.func) {
            // case "CPN_spLocationCheckCustomer":
            //     FuncApi = "CPN_spLocationCheckCustomer"
            //     break;
            default:
                break;
        }
        //End check select data redis
        var min = 1;
        var max = 100000;
        var rand =  min + (Math.random() * (max-min));

        let endcode = EncodeString(rand+"DFDFGFDGDGSEFERVCBJgrytgt45df21fhutgefdfgfdgdffsr3wery6ư6tydsfgthutrfsdỵtwersdfghkuytresdcjhrfdscvbnjuytrgfdsghytrefsdcvbhtresdvcbghtyredscvcbhtyrerdfscvbhtyresdcvbghtrefjhrgffewrxcvq354756efr2$%#%dhfgdferw4tdsdfhfggdsvffsdfdfsdYRWEFS"+ new Date());
        // call api
        let respone = yield apiWS.post(API_WS + FuncApi + "?token="+endcode, params)
        // check call api success
        if (respone && respone.status == 200) {
            respone.data === "" ? action.resolve([]) : action.resolve(JSON.parse(respone.data))
        }
        else {
            // api call fail
            action.reject(respone)
            yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });

        }
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
    }
    catch (e) {
        ///something wrong
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
        action.reject(e)

    }
}

export function* API_UploadFile(action) {
    debugger
    try {
        //show loading
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: true });

        //params received
        const params = action && action.params
        /// catch api die
        yield delay(300);

        //let endcode = EncodeString("DFDFGFDGDGSEFERVCBJgrytjhrgffewrxcvq354756efr2$%#%dhfgdferw4tdsdfhfggdsvffsdfdfsdYRWEFS"+ new Date());
        // call api
        let respone = yield apiWS.post(API_WS + "API_UploadFile", params)
        // check call api success
        if (respone && respone.status === 200) {
            action.resolve(respone.data)
        }
        else {
            // api call fail
            action.reject(respone)
            yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
        }
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
    }
    catch (e) {
        ///something wrong
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
        action.reject(e)
        //yield delay(300)
        //yield put({ type: mainTypes.ERROR, payload: true });
    }
}

export function* API_spCallServerSystem(action) {
    try {
        //show loading
        if (action.params.IsLoading === true)
            yield put({ type: mainTypes.LOADING_SUCCESS, payload: true });

        //params received
        const params = action && action.params
        params.API_key = APIKeyErp;
        let FuncApi = "API_spCallServer";
        /// catch api die
        yield delay(300);
        //Check select data redis
        switch (params.func) {
            case "APIC_GetEncoding":
                FuncApi = "APIC_GetEncoding"
                break;
            default:
                FuncApi = "API_spCallServer"
                break;
        }
        //End check select data redis
        let endcode = EncodeString("tryikyujtgfsadgtrfvhtyresdfvffhtrewdsDFDFGFDGDGSEFERVCBJgrytjhrgffewrxcvq354756efr2$%#%dhfgdferw4tdsdfhfggdsvffsdfdfsdYRWEFS"+ new Date());
        // call api
        let respone = yield apiErp.post(API_ERP + FuncApi + "?token="+endcode, params)

        // check call api success
        if (respone && respone.status == 200) {
            respone.data === "" ? action.resolve([]) : action.resolve(JSON.parse(respone.data))
        }
        else {
            // api call fail
            action.reject(respone)
            yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });

        }
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
    }
    catch (e) {
        ///something wrong
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
        action.reject(e)

    }
}

export function* API_spCallServerChat(action) {
    try {
        //show loading
        if (action.params.IsLoading === true)
            yield put({ type: mainTypes.LOADING_SUCCESS, payload: true });

        //params received
        const params = action && action.params
        let FuncApi = "API_spCallServer";
        /// catch api die
        yield delay(300);
        //Check select data redis
        switch (params.func) {
            case "SendMessage":
                FuncApi = "Chat_SendMessage_From_Customer"
                break;
            case "GetMessage":
                FuncApi = "Chat_GetMessage_From_Customer"
                break;
            default:
                FuncApi = "api_spcallserver"
                break;
        }
        //End check select data redis

        let endcode = EncodeString("hjh,kp;iokyhaghjkkjhnbvgfyhtfgfdcDFDFGFDGDGSEFERVCBJgrytjhrgffewrxcvq354756efr2$%#%dhfgdferw4tdsdfhfggdsvffsdfdfsdYRWEFS"+ new Date());

        // call api
        let respone = yield apiChat.post(API_CHAT + "/" + FuncApi + "?token="+endcode , params)

        // check call api success
        if (respone && respone.status == 200) {
            respone.data === "" ? action.resolve([]) : action.resolve(respone.data)
        }
        else {
            // api call fail
            action.reject(respone)
            yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });

        }
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
    }
    catch (e) {
        ///something wrong
        yield put({ type: mainTypes.LOADING_SUCCESS, payload: false });
        action.reject(e)

    }
}

export function* changeLanguage(action) {
    try {
        const language = action.params.language;
        const Type = action.params.Type;
        //let newLanguage = language == VN ? EN : VN;
        delay(300);
        yield put({ type: mainTypes.CHANGE_LANGUAGE_SUCCESS, payload: language })
        I18n.locale = language
        action.resolve(language)
    }
    catch (e) {
        action.reject(e)
    }
}

export function* checkLanguage(action) {
    try {
        const language = yield getData(LANE)
        const newLanguage = language !== null && language !== '' && JSON.parse(language) === 'en' ? JSON.parse(language) : 'vn'
        yield put({ type: mainTypes.CHECK_LANGUAGE_SUCCESS, payload: newLanguage })
        I18n.locale = newLanguage
    }
    catch (e) {
    }
}


export default function* watchMainActionSagas() {
    ///Watcher watch Sagas

    yield takeEvery(mainTypes.LOADING, LOADING);
    yield takeLatest(mainTypes.CHANGE_LANGUAGE, changeLanguage);
    yield takeLatest(mainTypes.CHECK_LANGUAGE, checkLanguage);
    yield takeEvery(mainTypes.CallApiWs, CallApiWs);
    yield takeEvery(mainTypes.API_UploadFile, API_UploadFile);
    yield takeEvery(mainTypes.API_spCallServerSystem, API_spCallServerSystem);
    yield takeEvery(mainTypes.API_spCallServerChat, API_spCallServerChat);
}