import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useInput } from "../../Hooks";
import { mainAction } from "../../Redux/Actions";
import { Alertwarning, Alertsuccess } from "../../Utils";
import I18n from "../../Language";
import { ContactForm, ContactFormLanding } from "../../Common";

export const PhoneContactLanding = () => {
  const dispatch = useDispatch();
  const [setDisabled] = useState(false);
  const location = useLocation();

  const [Phone, setPhone] = useInput("");
  const PhoneRef = useRef();

  const [Type] = useState(0);
  const [TypeContact] = useState("");

  const [AreaId] = useState(0);
  const [Area] = useState("");
  const [Url, setUrl] = useState("https://gtelpost.vn");

  useEffect(() => {
    let domain = "https://gtelpost.vn";
    setUrl(domain + location.pathname);
  }, [location]);

  const onActionPhone = async () => {
    if (Type === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredType"));
      return;
    } else if (AreaId === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredArea"));
      return;
    } else if (Phone === "") {
      Alertwarning(I18n.t("ContactForm.RequiredPhone"));
      PhoneRef.current.focus();
      return;
    } else {
      setDisabled(true);
      const pr = {
        Json:
          '{"Url":"' +
          Url +
          '","Phone":"' +
          Phone +
          '", "Type":"' +
          Type +
          '", "TypeName":"' +
          TypeContact +
          '", "AreaId":"' +
          AreaId +
          '", "AreaName": "' +
          Area +
          '","Status":1, "StatusName":"Chưa xử lý"}',
        func: "CRM_spCustomerContactRequest_Save",
      };
      try {
        const list = await mainAction.API_spCallServerSystem(pr, dispatch);
        onSendNofity();
        if (list !== []) {
          Alertsuccess(I18n.t("ContactForm.Success"));
          onReset();
        }

        setDisabled(false);
      } catch (err) {
        setDisabled(false);
      }
    }
  };

  const onReset = () => {
    setPhone("");
  };

  const onSendNofity = async () => {
    const NotifiParam = {
      Json: JSON.stringify({
        CustomerId: 0,
        FuncSend: "CallRequest",
        JsonData: [
          {
            Area: AreaId,
            Phone: Phone,
            Type: Type,
          },
        ],
      }),
      func: "APIC_spSendNotification",
    };
    const resultNotify = await mainAction.API_spCallServerSystem(
      NotifiParam,
      dispatch
    );
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 1000) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="block-absolute">
        <a
          className="block-img"
          href="https://www.facebook.com/www.gtelpost.vn"
          target="_blank"
          title="GTELPOST"
        >
          <div className="">
            <img
              className="main-img"
              src="/assets/img/Facebook-80.png"
              alt="phone"
            />
          </div>
        </a>
        <a
          className="block-img"
          href="https://zalo.me/4595161656116022900"
          target="_blank"
          title="GTELPOST"
        >
          <div className="">
            <img
              className="main-img"
              src="/assets/img/Zalo-80.png"
              alt="phone"
            />
          </div>
        </a>
        <a
          className="block-img"
          href="#"
          data-toggle="modal"
          data-target="#phoneModal"
        >
          <div className="">
            <img
              className="main-img"
              src="/assets/img/Call-80.png"
              alt="phone"
            />
          </div>
        </a>
        <a
          href="#"
          id="btnShowPopup"
          className="display-none"
          data-toggle="modal"
          data-target="#popupModal"
        >
          <div
            className="item-absolute"
            style={{
              lineHeight: "25px",
              width: "37px",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            <i className="fa fa-bell-o text-green"></i>
          </div>
        </a>
      </div>
      <div
        className="modal fade contact_form_phone"
        id="phoneModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="phoneModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog phone_landing" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <button
                type="button"
                className="close close_btn_landing"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="box-contact">
                <div className="h5">Thông tin hỗ trợ khách hàng</div>
                <ContactFormLanding key="home-contact" />
              </div>
              <p className="text-center">{I18n.t("ContactForm.NotePhone")}</p>
              <p className="h4 text-center grey80">1900 6463</p>
              <p className="h4 text-center grey80">02438 356 356</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="emailModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="emailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 className="grey80">{I18n.t("ContactForm.ContactEmail")}</h5>
              <ContactFormLanding key="block-contact" />
            </div>
          </div>
        </div>
      </div>
      {showButton && (
        <button className="back-to-top" onClick={scrollToTop}>
          <img src="/assets/img/up-arrow.png" alt="phone" />
        </button>
      )}
    </>
  );
};
