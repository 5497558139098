import React, { useEffect, useState } from "react";
import { ContactFormLanding } from "../../Common";
import { mainAction } from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import { FILE_URL } from "../../Services";
import LandingLayout from "../../Layout/LandingLayout";

const LandingHome = () => {
  useEffect(() => {
    onGetData();
  }, []);
  const [LogoPartner, setLogoPartner] = useState([]);
  const dispatch = useDispatch();
  const [ContactInfo, setContactInfo] = useState([]);
  const [Company, setCompany] = useState([]);
  const [Apps, setApps] = useState([]);

  const onGetData = async () => {
    let keyLang = localStorage.getItem("keyLang");
    const params = {
      Json: JSON.stringify([
        { Type: "Company", Enabled: 1, Lang: keyLang },
        { Type: "ContactInfo", Enabled: 1, Lang: keyLang },
        { Type: "Apps", Enabled: 1, Lang: keyLang },
        { Type: "Partner", Enabled: 1, Lang: keyLang },
      ]),
      func: "WS_spGetInformation",
    };

    try {
      const list = await mainAction.CallApiWs(params, dispatch);
      setContactInfo(list.filter((p) => p.Type === "ContactInfo"));
      setCompany(list.filter((p) => p.Type === "Company"));
      setApps(list.filter((p) => p.Type === "Apps" && p.InHome === 1));
      setLogoPartner(list.filter((p) => p.Type === "Partner"));
    } catch (err) {}
  };
  return (
    <LandingLayout>
      <div id="Landing-page">
        {/* Header */}
        <div className="Header_landing">
          <span>
            <img
              className="logo_netco_landing"
              src="/assets/img/logo.png"
              alt=""
            />
          </span>
          <span className="title_adv_header">
            <a href="#Advantage">Ưu điểm</a>
          </span>
          <span className="title_contact_header">
            <a href="#contact_landing">Liên hệ báo giá ngay</a>
          </span>
        </div>

        {/* Banner */}
        <div className="banner_landing">
          <img src="/assets/img/banner_landing01.jpg" alt="" />
        </div>
        {/*End Banner */}

        {/* Contact */}
        <div id="contact_landing" className="contact_landing_main">
          <div className="img_bg_contact">
            <img src="/assets/img/transp_head1_1920.jpg" alt="" />
          </div>
          <div className="info_contact_full">
            <div className="title_contact">ĐĂNG KÝ ĐỂ NHẬN BÁO GIÁ NGAY!</div>
            <div className="box_contact_landing">
              <div className="h5">Thông tin hỗ trợ khách hàng</div>
              <div className="contact_form_main">
                <ContactFormLanding key="home-contact" />
              </div>
            </div>
          </div>
        </div>
        {/*End Contact */}
        {/* Service */}
        <div className="service_landing">
          <div className="container">
            <div className="row mt-2">
              <h3 className="h3_title_service1">TẤT CẢ DỊCH VỤ CỦA</h3>
              <h3 className="h3_title_service">GTELPOST</h3>
              <div className="col-md-12 main_box_service">
                <div className="col-md-4 col-xs-6">
                  <div className=" service_box_landing">
                    <img
                      className="service_img_landing"
                      src="/assets/img/icon-01.png"
                      alt=""
                    />
                    <p className="service_title">Chuyển phát trong ngày</p>
                    <div className="btn_view_service">
                      <a href="#contact_landing">Xem Thêm</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className=" service_box_landing">
                    <img
                      className="service_img_landing"
                      src="/assets/img/icon-02.png"
                      alt=""
                    />
                    <p className="service_title">Chuyển phát nhanh</p>
                    <div className="btn_view_service">
                      <a href="#contact_landing">Xem Thêm</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className=" service_box_landing">
                    <img
                      className="service_img_landing"
                      src="/assets/img/icon-05.png"
                      alt=""
                    />
                    <p className="service_title">Chuyển phát tiết kiệm</p>
                    <div className="btn_view_service">
                      <a href="#contact_landing">Xem Thêm</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className=" service_box_landing">
                    <img
                      className="service_img_landing"
                      src="/assets/img/icon-03.png"
                      alt=""
                    />
                    <p className="service_title">Chuyển phát tiêu chuẩn</p>
                    <div className="btn_view_service">
                      <a href="#contact_landing">Xem Thêm</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className=" service_box_landing">
                    <img
                      className="service_img_landing"
                      src="/assets/img/icon-06.png"
                      alt=""
                    />
                    <p className="service_title">Chuyển phát theo yêu cầu</p>
                    <div className="btn_view_service">
                      <a href="#contact_landing">Xem Thêm</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className=" service_box_landing">
                    <img
                      className="service_img_landing"
                      src="/assets/img/icon-04.png"
                      alt=""
                    />
                    <p className="service_title">Thuê kho, Fulfillment</p>
                    <div className="btn_view_service">
                      <a href="#contact_landing">Xem Thêm</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Service */}

        {/* Procedure */}
        <div className="procedure_img_landing">
          <div className="img_bg_procedure">
            <img src="/assets/img/transp_head2_1920.jpg" alt="" />
          </div>
        </div>
        {/* End Procedure*/}

        {/* Advantage*/}
        <div id="Advantage" className="advantage_landing">
          <div className="cicle_advan"></div>
          <div className="container">
            <h3 className="title_advan">ĐIỂM MẠNH</h3>
            <h3 className="title_advan title_noibat">NỔI BẬT CỦA GTELPOST?</h3>
            <div className="row form_advan">
              <div className="col-md-4 col-sm-6 col-xs-6 point_full">
                <div className="advan_point">
                  <img
                    className="cicle_icon"
                    src="/assets/img/cicle_icon1.png"
                    alt=""
                  />
                  <div className="border_point">63</div>
                </div>
                <div className="title_point">
                  Tỉnh thành, giao nhận toàn bộ 63 tỉnh thành
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6 point_full">
                <div className="advan_point">
                  <img
                    className="cicle_icon"
                    src="/assets/img/cicle_icon1.png"
                    alt=""
                  />
                  <div className="border_point">93</div>
                </div>
                <div className="title_point">
                  Chi nhánh và điểm phát hàng (POD) trên toàn quốc
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6 point_full">
                <div className="advan_point">
                  <img
                    className="cicle_icon"
                    src="/assets/img/cicle_icon1.png"
                    alt=""
                  />
                  <div className="border_point">04</div>
                </div>
                <div className="title_point">Trung tâm điều hành kho vận</div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6 point_full">
                <div className="advan_point">
                  <img
                    className="cicle_icon"
                    src="/assets/img/cicle_icon1.png"
                    alt=""
                  />
                  <div className="border_point2">1750+</div>
                </div>
                <div className="title_point">Phương tiện các loại</div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6 point_full">
                <div className="advan_point">
                  <img
                    className="cicle_icon"
                    src="/assets/img/cicle_icon1.png"
                    alt=""
                  />
                  <div className="border_point2">4500+</div>
                </div>
                <div className="title_point">Nhân sự sẵn sàng phục vụ</div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6 point_full">
                <div className="advan_point">
                  <img
                    className="cicle_icon"
                    src="/assets/img/cicle_icon1.png"
                    alt=""
                  />
                  <div className="border_point2">2500+</div>
                </div>
                <div className="title_point">Xe máy trên mọi cung đường</div>
              </div>
            </div>
            <a href="#contact_landing" className="button_advise">
              <p className="title_advise">NHẬN BÁO GIÁ NGAY</p>
              <img
                className="img_advise"
                src="/assets/img/icons8-next-50.png"
                alt=""
              />
            </a>
          </div>
        </div>
        {/* End Advantage*/}

        {/* Info*/}
        <div className="info_landing">
          <div className="container">
            <div className="main_content">
              <h2 className="title_info1">VÌ SAO</h2>
              <h2 className="title_info2">LẠI LỰA CHỌN GTELPOST ?</h2>
              <div className="row">
                <div className="col-md-6 main_line_content">
                  <div className="content_line1">
                    <img
                      className="image_tick_icon1"
                      src="/assets/img/tick_icon.png"
                      alt=""
                    />
                    <div className="regimes_content">
                      Kiểm tra đơn hàng chỉ 1 Click
                    </div>
                  </div>
                  <div className="content_line2">
                    <img
                      className="image_tick_icon1"
                      src="/assets/img/tick_icon.png"
                      alt=""
                    />
                    <div className="regimes_content">
                      Theo dõi realTime tình hình đơn hàng
                    </div>
                  </div>
                  <div className="content_line3">
                    <img
                      className="image_tick_icon1"
                      src="/assets/img/tick_icon.png"
                      alt=""
                    />
                    <div className="regimes_content">
                      Chỉ cần Smartphone check mọi nơi đến
                    </div>
                  </div>
                  <div className="content_line4">
                    <img
                      className="image_tick_icon1"
                      src="/assets/img/tick_icon.png"
                      alt=""
                    />
                    <div className="regimes_content">
                      Xử lý khiếu nại nhanh chóng trong 24h
                    </div>
                  </div>
                  <div className="content_line5">
                    <img
                      className="image_tick_icon1"
                      src="/assets/img/tick_icon.png"
                      alt=""
                    />
                    <div className="regimes_content">
                      {" "}
                      Đội ngũ phục vụ riêng cho từng khách hàng{" "}
                    </div>
                  </div>
                  <div className="content_line6">
                    <img
                      className="image_tick_icon1"
                      src="/assets/img/tick_icon.png"
                      alt=""
                    />
                    <div className="regimes_content">
                      Áp dụng hệ thống quản lý ISO 9001 - 2015
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 image_regimes">
                <div className="background_img"></div>
                <img
                  className="img_regimes"
                  src="/assets/img/profive1.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <a href="#contact_landing" className="content_button">
            <p className="regimes_content_button">NHẬN BÁO GIÁ NGAY</p>
            <img
              className="img_content_button"
              src="/assets/img/icons8-next-50.png"
              alt=""
            />
          </a>
        </div>
        {/* End Info*/}

        {/* Partner */}
        <div className="partners_landing">
          <div className="partners">
            <div className="container">
              <div className="title_partners mt-2">
                <div className="title_h4 mt-4">
                  ĐỐI TÁC TIN TƯỞNG DỊCH VỤ CỦA
                </div>
                <div className="title_h2">GTELPOST</div>
              </div>
              <div className="slider">
                {LogoPartner.map((item, index) => {
                  return (
                    <div key={item.Id + "PARTNER"}>
                      <a
                        href={item.Url}
                        target="_blank"
                        rel="noreferrer"
                        title="item.Title"
                      >
                        <img alt={item.Title} src={FILE_URL + item.ImgThumb} />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/*End Partner */}
      </div>
    </LandingLayout>
  );
};

export default LandingHome;
