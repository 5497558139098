import Geocode from "react-geocode";
//Geocode.setApiKey("AIzaSyAxFCqPJ3tcb9DK66k0GkrUaUAt2qwRfrI");

export const GetLatLngGoogle = async (Address) => {
    try {
        Geocode.setApiKey("AIzaSyBUBW5JbPqpurOUq2iV3Ys3rx59IktH1-s");
        const response = await Geocode.fromAddress(Address);;
        if (response) {
            return [{
                lat:response.results[0].geometry.location.lat, 
                lng:response.results[0].geometry.location.lng,
                NameWard:response.results[0].address_components[0].long_name
            }];
        } else {
            return "False";
        }
    }
    catch (e) {
        return "False";
    }
};