import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { useInput } from "../../Hooks";
import { mainAction } from "../../Redux/Actions";
import { MapView } from "../../Common";
import { Alertwarning, Alertsuccess } from "../../Utils";
import { APIKey } from "../../Services";
import I18n from "../../Language";
import MainLayout from "../../Layout/MainLayout";
export const Complain = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();

  const [Name, bindName, setName] = useInput("");
  const [Phone, bindPhone, setPhone] = useInput("");
  const [Code, bindCode, setCode] = useInput("");
  const [Content, bindContent, setContent] = useInput("");
  const [Type, setType] = useState(1);

  const NameRef = useRef();
  const PhoneRef = useRef();
  const CodeRef = useRef();
  const ContentRef = useRef();
  const onAction = async () => {
    if (Name === "") {
      Alertwarning(I18n.t("Complain.RequiredName"));
      NameRef.current.focus();
      return;
    } else if (Phone === "") {
      Alertwarning(I18n.t("Complain.RequiredPhone"));
      PhoneRef.current.focus();
      return;
    } else if (Content === "") {
      Alertwarning(I18n.t("Complain.RequiredContent"));
      ContentRef.current.focus();
      return;
    } else {
      setDisabled(true);
      let params = {
        CustomerId: 0,
        Name: Name,
        Phone: Phone,
        LadingCode: Code,
        Type: parseInt(Type),
        Content: Content,
        SendFrom: "TỪ GTELPOST WEBSITE",
      };
      const pr = {
        ApiKey: APIKey,
        Json: JSON.stringify(params),
        func: "APIC_spCustomerComplain_JsonAuto",
        IsLoading: true,
      };
      try {
        const list = await mainAction.API_spCallServerSystem(pr, dispatch);
        if (list.length > 0) {
          Alertsuccess(I18n.t("ContactForm.Success"));
          onReset();
          onSendNofity();
          history.push({
            pathname: "/thankyou",
            state: 1,
          });
        }
        setDisabled(false);
      } catch (err) {
        setDisabled(false);
      }
    }
  };

  const onReset = () => {
    setName("");
    setPhone("");
    setCode("");
    setContent("");
  };

  const onSendNofity = async () => {
    const NotifiParam = {
      Json: JSON.stringify({
        CustomerId: 0,
        FuncSend: "ComplainCreate",
        SendFrom: "TỪ GTELPOST WEBSITE",
        JsonData: [
          {
            Name: Name,
            Phone: Phone,
            Content: Content,
          },
        ],
      }),
      func: "APIC_spSendNotification",
      API_key: APIKey,
    };
    const resultNotify = await mainAction.API_spCallServerSystem(
      NotifiParam,
      dispatch
    );
    if (resultNotify.length > 0) return;
  };

  return (
    <MainLayout>
      <section className="content">
        <div className="container">
          <div className="sidemap">
            <span>
              <Link to="/">{I18n.t("TopMenu.Home")}</Link>
            </span>
            <span>
              <i className="fa fa-angle-right"></i>
            </span>
            <span>{I18n.t("Complain.Title")}</span>
          </div>
          <h3>{I18n.t("Complain.Title")}</h3>
          <div className="row mt10">
            <div className="col-md-6 form-pd">
              <div className="form">
                <div className="form-group">
                  <label>
                    {I18n.t("Complain.InputName")}{" "}
                    <span className="text-red">(*)</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    ref={NameRef}
                    value={Name}
                    {...bindName}
                    maxLength="50"
                    placeholder={I18n.t("Complain.InputName")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    {I18n.t("Complain.InputPhone")}{" "}
                    <span className="text-red">(*)</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    ref={PhoneRef}
                    value={Phone}
                    {...bindPhone}
                    maxLength="15"
                    placeholder={I18n.t("Complain.InputPhone")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    {I18n.t("Complain.InputCode")}{" "}
                    <span className="text-red">(*)</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    ref={CodeRef}
                    value={Code}
                    {...bindCode}
                    maxLength="50"
                    placeholder={I18n.t("Complain.InputCode")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    {I18n.t("Complain.InputType")}{" "}
                    <span className="text-red">(*)</span>
                  </label>
                  <select
                    className="form-control"
                    value={Type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="1">
                      {I18n.t("Complain.ComplainService")}
                    </option>
                    <option value="2">
                      {I18n.t("Complain.ComplainPrice")}
                    </option>
                    <option value="3">{I18n.t("Complain.ComplainCod")}</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    {I18n.t("Complain.InputContent")}{" "}
                    <span className="text-red">(*)</span>
                  </label>
                  <textarea
                    className="form-control"
                    rows="4"
                    required
                    ref={ContentRef}
                    value={Content}
                    {...bindContent}
                    placeholder={I18n.t("Complain.ShortDesc")}
                  ></textarea>
                </div>
                <div className="form-group text-right mb20">
                  <button
                    type="text"
                    className="btn btn-success pd-30"
                    disabled={disabled}
                    onClick={(e) => onAction()}
                  >
                    {I18n.t("Complain.ButtonSend")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <MapView key="hhjgd" />
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
