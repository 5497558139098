import React, { useState, useEffect, useRef, useDebugValue } from "react";
import { useDispatch } from "react-redux";

import { useInput } from "../Hooks";
import { mainAction } from "../Redux/Actions";
import { APIKeySystem } from "../Services";
import I18n from "../Language";
import { GMap } from ".";
import { PositionData, Alertwarning, RemoveAccents } from "../Utils";
import { useLocation } from "react-router-dom";

const PostOfficeComp = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();

  const [DataPO, setDataPO] = useState([]);
  const [DataFilterPO, setDataFilterPO] = useState([]);

  const [DataDirection, setDataDirection] = useState([]);
  const [DataZoom, setDataZoom] = useState(null);

  const [TextFilter, bindTextFilter, setTextFilter] = useInput("");
  const TextFilterRef = useRef();

  useEffect(() => {
    onGetData();
  }, []);
  console.log(DataPO);
  //#region Get Danh sách bưu cục

  const onGetData = async () => {
    try {
      let pr = {
        Json: "",
        func: "APIC_spPostOffice_GetMany",
      };
      const data = await mainAction.API_spCallServerSystem(pr, dispatch);
      setDataPO(data);
      setDataFilterPO(data);
    } catch (err) {}
    mainAction.LOADING({ IsLoading: false }, dispatch);
  };

  const onFilterPO = (e) => {
    let keySearch = RemoveAccents(TextFilter.trim().toUpperCase());
    setDataFilterPO(
      DataPO.filter(
        (p) =>
          keySearch === "" ||
          RemoveAccents(p.POName).toUpperCase().indexOf(keySearch) !== -1 ||
          RemoveAccents(p.POAddress).toUpperCase().indexOf(keySearch) !== -1
      )
    );
  };

  const HandleKeyDownPO = (e) => {
    if (e.key === "Enter") {
      let keySearch = RemoveAccents(TextFilter.trim().toUpperCase());
      setDataFilterPO(
        DataPO.filter(
          (p) =>
            keySearch === "" ||
            RemoveAccents(p.POName).toUpperCase().indexOf(keySearch) !== -1 ||
            RemoveAccents(p.POAddress).toUpperCase().indexOf(keySearch) !== -1
        )
      );
    }
  };

  //#endregion

  //#region Get địa chỉ của user

  const DrawDirection = (item) => {
    let a = [],
      b = PositionData();
    if (b !== undefined && b !== null && b !== "") {
      a.push(b);
      a.push({ lat: item.Lat, lng: item.Lng, label: item.POName });
      setDataDirection(a);
      setDataZoom(null);
    } else {
      //Alertwarning("Bạn cần cho phép netco.com.vn truy cập vị trí để xem chỉ đường !");
      setDataDirection([]);
      setDataZoom({
        Lat: parseFloat(item.Lat),
        Lng: parseFloat(item.Lng),
        PostOfficeID: item.PostOfficeID,
      });
    }
  };
  //#endregion

  return (
    <div className="row">
      <div className="col-md-6 radius">
        <div className="row po">
          <div className="col-md-12">
            <div className="form-group">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  ref={TextFilterRef}
                  value={TextFilter}
                  {...bindTextFilter}
                  placeholder={I18n.t("PostOfficePage.FindPO")}
                  onKeyDown={(e) => HandleKeyDownPO(e)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={(e) => {
                      onFilterPO();
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="scroller row">
              {DataFilterPO.map((item, index) => {
                return (
                  <div className="col-md-12" key={"polist1" + index}>
                    <div className=" po-item">
                      <div className="po-name">
                        <span>
                          {index + 1}. {item.POName}{" "}
                        </span>
                        <div
                          className="pull-right text"
                          onClick={(e) => DrawDirection(item)}
                        >
                          {I18n.t("PostOfficePage.Direct")}{" "}
                          <i className="fa fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <img src="/assets/img/icon24/phone.png" />{" "}
                        {item.POPhone}
                      </div>
                      <div className="po-address">
                        <img src="/assets/img/icon24/location.png" />{" "}
                        {item.AddressName}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="map-area">
          <GMap
            dataMarker={DataFilterPO}
            dataDirection={DataDirection}
            dataZoom={DataZoom}
          />
        </div>
      </div>
    </div>
  );
};

export const PostOffice = React.memo(PostOfficeComp);
