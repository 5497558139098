import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtml from "raw-html-react";
import MetaTags from "react-meta-tags";
import { useInput } from "../../Hooks";

import {
  APIKey,
  FILE_URL,
  CUSTOMER_CREATE_LADING_URL,
} from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import MainLayout from "../../Layout/MainLayout";
import {
  ScrollTop,
  FormatDateJson,
  Alertwarning,
  Alertsuccess,
} from "../../Utils";
import I18n from "../../Language";

export const CarreersApply = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);

  const [Info, setInfo] = useState({});
  const [List, setList] = useState([]);

  const [Name, bindName, setName] = useInput("");
  const [Email, bindEmail, setEmail] = useInput("");
  const [Phone, bindPhone, setPhone] = useInput("");
  const [FileUpload, setFileUpload] = useState({});

  const NameRef = useRef();
  const EmailRef = useRef();
  const PhoneRef = useRef();

  useEffect(() => {
    const pr = new URLSearchParams(location.search);
    if (pr.get("title") !== "") {
      onGetData(pr.get("title"));
      onGetList(pr.get("title"));
    } else history.push("/tuyen-dung");
  }, [location]);

  const onGetData = async (url) => {
    const params = {
      Json: '{"Url":"' + url + '"}',
      func: "WS_spGetRecruitment_Detail",
    };

    try {
      const list = await mainAction.CallApiWs(params, dispatch);
      let info = list[0];
      setInfo(info);
      ScrollTop();
    } catch (err) {}
  };

  const onGetList = async (url) => {
    const params = {
      Json: '{"Take":"6"}',
      func: "WS_spGetRecruitment",
    };

    try {
      const list = await mainAction.CallApiWs(params, dispatch);
      setList(list.filter((p) => p.Url !== url));
      ScrollTop();
    } catch (err) {}
  };

  const SEO = (
    <MetaTags>
      <title>
        GTELPOST - {I18n.t("TopMenu.Carreers")} - {Info?.Name}
      </title>
      <meta name="description" content={Info?.MetaDescription} />
      <meta property="og:image" content={Info?.ImgThumb} />
      <meta property="og:title" content={Info?.Name} />
      <meta property="og:description" content={Info?.MetaDescription} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content={Info?.Name} />
    </MetaTags>
  );

  const SaveCandidate = async () => {
    if (Name === "") {
      Alertwarning(I18n.t("AgentPage.RequiredName"));
      NameRef.current.focus();
      return;
    } else if (Email === "") {
      Alertwarning(I18n.t("AgentPage.RequiredEmail"));
      EmailRef.current.focus();
      return;
    } else if (Phone === "") {
      Alertwarning(I18n.t("AgentPage.RequiredPhone"));
      PhoneRef.current.focus();
      return;
    }
    //Upload file
    try {
      const formData = new FormData();
      formData.append("myFile", FileUpload, FileUpload.name);
      formData.append("key", "check");
      debugger;
      const data = await mainAction.API_UploadFile(formData, dispatch);
      if (data !== "") {
        debugger;
        const params = {
          Json: JSON.stringify({
            Name: Name,
            Phone: Phone,
            Email: Email,
            Profive: data,
            RecruidId: Info.Id,
          }),
          func: "WS_spSetCandidate",
          IsLoading: true,
        };
        setDisabled(true);
        const list = await mainAction.CallApiWs(params, dispatch);
        Alertsuccess(I18n.t("AgentPage.Success"));
        setName("");
        setPhone("");
        setEmail("");
        setDisabled(false);
        ScrollTop();
      } else {
        Alertwarning(I18n.t("Bạn chưa upload hồ sơ !"));
        return;
      }
    } catch (err) {}
  };

  const onFileChange = (event) => {
    setFileUpload(event.target.files[0]);
  };

  return (
    <MainLayout>
      <section className="content">
        {SEO}
        <div className="container">
          <div className="sidemap">
            <span>
              <Link to="/">{I18n.t("TopMenu.Home")}</Link>
            </span>
            <span>
              <i className="fa fa-angle-right"></i>
            </span>
            <span>
              <Link to="/tuyen-dung">{I18n.t("TopMenu.Carreers")}</Link>
            </span>
            <span>
              <i className="fa fa-angle-right"></i>
            </span>
            <span>{Info?.Name}</span>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="pd15 bg-white radius pb30">
                <h1 className="h3 mb10 pt10">Nộp đơn ứng tuyển</h1>
                <p className="text-green title2">{Info?.Name}</p>
                <p>
                  <b>Nơi làm việc:</b> {Info?.City}
                </p>
                <p>
                  <b>Mức lương:</b> {Info?.Salary}
                </p>
                <p>
                  <b>Hạn chót nhận hồ sơ:</b> {FormatDateJson(Info?.EndDate, 1)}
                </p>
                <hr />
                <div className="row">
                  <div className="col-md-12 text-green title2 mb10 m10">
                    THÔNG TIN LIÊN HỆ CỦA BẠN
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="special-label">
                        {I18n.t("ContactPage.InputName")}{" "}
                        <span className="text-red">(*)</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        ref={NameRef}
                        value={Name}
                        {...bindName}
                        maxLength="50"
                        placeholder={I18n.t("ContactForm.InputName")}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="special-label">
                        {I18n.t("ContactPage.InputEmail")}{" "}
                        <span className="text-red">(*)</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        ref={EmailRef}
                        value={Email}
                        {...bindEmail}
                        maxLength="50"
                        placeholder={I18n.t("ContactForm.InputEmail")}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="special-label">
                        {I18n.t("ContactPage.InputPhone")}{" "}
                        <span className="text-red">(*)</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        ref={PhoneRef}
                        value={Phone}
                        {...bindPhone}
                        maxLength="15"
                        placeholder={I18n.t("ContactForm.InputPhone")}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="special-label">
                        Hồ sơ của bạn <span className="text-red">(*)</span>{" "}
                        <small className="italic grey40">
                          Hỗ trợ định dạng *.doc, *.docx, *.pdf không quá 2MB
                        </small>
                      </label>
                      <input
                        className="form-control"
                        onChange={(e) => onFileChange(e)}
                        type="file"
                        required
                        accept="application/pdf,application/msword"
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        disabled={disabled}
                        className="btn btn-success btn-sm"
                        onClick={(e) => SaveCandidate()}
                      >
                        <i className="fa fa-edit"></i> Ứng tuyển
                      </button>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 sidebar-right">
              <h5 className="mb20 pt20 bold">
                {I18n.t("Sidebar.CarreersAnother")}
              </h5>
              <div className="row">
                {List.map((item, index) => {
                  return (
                    <div className="col-md-12" key={"news2" + index}>
                      <div className="box-news-right">
                        <Link to={"/thong-tin-tuyen-dung?title=" + item.Url}>
                          <img
                            className="img-thumb"
                            src={FILE_URL + item.ImgThumb}
                            alt={item.Title}
                            onerror="this.onerror = null; this.src = 'https://admin.gtelpost.vn/Image/ckfinder/files/logoNew.png';"
                          />
                          <div className="item-right">
                            <div className="text-sm">{item.Name}</div>
                            <div className="text-xs">
                              <i className="fa fa-clock"></i>{" "}
                              {FormatDateJson(item.CreateOn, 1)}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
                <div className="col-md-12 box-share mb10">
                  <div className="text-lg-bolder mb10 mt20">
                    {I18n.t("News.ShareToSocial")}
                  </div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=" +
                      window.location.href
                    }
                    onClick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                  >
                    <img
                      src="/assets/img/fb-square.png"
                      alt="Share on Facebook"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://plus.google.com/share?url=" +
                      window.location.href
                    }
                    onClick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                  >
                    <img
                      src="/assets/img/gg-square.png"
                      alt="Share on Google+"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://twitter.com/intent/tweet?original_referer=" +
                      window.location.href +
                      "%2F&amp;source=tweetbutton&amp;text=" +
                      Info.Title +
                      "&amp;url=" +
                      window.location.href +
                      "%2F&amp;via="
                    }
                    onclick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                  >
                    <img
                      src="/assets/img/tw-square.png"
                      alt="Share on Twitter"
                    />
                  </a>
                </div>
                <div className="col-md-12">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={CUSTOMER_CREATE_LADING_URL}
                    className="btn btn-success w-100 white"
                  >
                    {I18n.t("Banner.CreateLading")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
