import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import I18n from '../../Language';

export const Footer = () => {

  const dispatch = useDispatch();

  const [About, setAbout] = useState([]);
  const [Helper, setHelper] = useState([]);
  const [Policy, setPolicy] = useState([]);
  const [Rules, setRules] = useState([]);
  const [Company, setCompany] = useState([]);
  const [ContactInfo, setContactInfo] = useState([]);
  const [Social, setSocial] = useState([]);
  const [Apps, setApps] = useState([]);

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = async () => {
    let keyLang = localStorage.getItem("keyLang");
    const params = {
      Json: JSON.stringify([
        { "Type": "About", "Enabled": 1, "Lang": keyLang },
        { "Type": "Helper", "Enabled": 1, "Lang": keyLang },
        { "Type": "Rules", "Enabled": 1, "Lang": keyLang },
        { "Type": "Policy", "Enabled": 1, "Lang": keyLang },
        { "Type": "Company", "Enabled": 1, "Lang": keyLang },
        { "Type": "ContactInfo", "Enabled": 1, "Lang": keyLang },
        { "Type": "Apps", "Enabled": 1, "Lang": keyLang },
        { "Type": "Social", "Enabled": 1, "Lang": keyLang }
      ]),
      func: "WS_spGetInformation",
    };
    try {
      const list = await mainAction.CallApiWs(params, dispatch);
      setAbout(list.filter(p => p.Type === "About"));
      setHelper(list.filter(p => p.Type === "Helper"));
      setPolicy(list.filter(p => p.Type === "Policy"));
      setRules(list.filter(p => p.Type === "Rules"));
      setCompany(list.filter(p => p.Type === "Company"));
      setContactInfo(list.filter(p => p.Type === "ContactInfo"));
      setSocial(list.filter(p => p.Type === "Social"));
      setApps(list.filter(p => p.Type === "Apps" && p.InHome === 1));
    } catch (err) {
    }
  };

  return (
    <footer>
      <div className="mask">
        <div className="container">
          <div className="row">
            <div className="col-md-3 box-menu">
              <h4 className="text-lg-bold text-green">{I18n.t('Footer.AboutCompany')}</h4>
              {
                About.map((item, index) => {
                  return (<div className="text" key={item.Id}><Link title={item.Title} to={"/thong-tin-cong-ty?title=" + item.Url}><i className="fa fa-chevron-right"></i> {item.Title}</Link></div>)
                })
              }

            </div>
            <div className="col-md-3 box-menu">
              <h4 className="text-lg-bold text-green">{I18n.t('Footer.Policy')}</h4>
              {
                Policy.map((item, index) => {
                  return (<div className="text" key={item.Id}><Link title={item.Title} to={"/chinh-sach?title=" + item.Url}><i className="fa fa-chevron-right"></i> <span>{item.Title}</span></Link></div>)
                })
              }
            </div>
            <div className="col-md-3 box-menu">
              <h4 className="text-lg-bold text-green">{I18n.t('Footer.Helper')}</h4>
              {
                Helper.map((item, index) => {
                  return (<div className="text" key={item.Id}><Link title={item.Title} to={"/tro-giup?title=" + item.Url}><i className="fa fa-chevron-right"></i> <span>{item.Title}</span></Link></div>)
                })
              }
            </div>
            <div className="col-md-3 box-menu">
              <h4 className="text-lg-bold text-green">{I18n.t('Footer.Rules')}</h4>
              {
                Rules.map((item, index) => {
                  return (<div className="text" key={item.Id}><Link title={item.Title} to={"/quy-dinh-chung?title=" + item.Url}><i className="fa fa-chevron-right"></i> <span>{item.Title}</span></Link></div>)
                })
              }
            </div>
          </div>
          <div className="row mt50">
            <div className="col-md-6 box-menu">
              <h4 className="text-lg-bold text-green">{I18n.t('Footer.CompanyName')}</h4>
              {
                Company.map((item, index) => {
                  return (<div className="box-text" key={item.Id}><div className="text-bold"><i className="fa fa-chevron-right"></i> {item.Title}</div><div className="text">{item.ShortDesc}</div></div>)
                })
              }
            </div>
            <div className="col-md-3 box-menu">
              <h4 className="text-lg-bold text-green">{I18n.t('Footer.Hotline')}</h4>
              {
                ContactInfo.map((item, index) => {
                  return (<div className="box-text" key={item.Id}><div className="text nowrap"><img alt={item.Title} src={FILE_URL + item.ImgThumb} /> {item.Title}</div><div className="ml30">{item.ShortDesc}</div></div>)
                })
              }
            </div>
            <div className="col-md-3 box-menu">
              <h4 className="text-lg-bold text-green">{I18n.t('Footer.ConnectWithUs')}</h4>
              {/* {
                Social.map((item, index) => {
                  return (item.Keys == 1 ? (<a title={item.Title} key={item.Id} href={item.Url} className="box-img" target="_blank" rel="noreferrer"><img alt={item.Title} height="40" src={FILE_URL + item.ImgThumb} /></a>) : (<div className="text mt20" key={index}><a title={item.Title} href={item.Url} target="_blank" rel="noreferrer"><img alt={item.Title} height="60" src={FILE_URL + item.ImgThumb} /></a></div>))
                })
              } */}
              <ul>
                {
                  Apps.map((item, index) => {
                    return (
                      <li key={"App" + index}>
                        <a href={item.Url} target="_blank" rel="noreferrer" title={item.Title}>
                          <img style={item.Keys === "1" ? { height: "48px" } : { maxWidth: "180px" }} src={FILE_URL + item.ImgThumb} alt={item.Title} />
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
